import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 15,
    "@media(min-width: 280px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 320px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 360px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 400px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 600px)": {
      width: "calc(100%/3)",
      height: "100%",
    },
    "@media(min-width: 960px)": {
      width: "calc(100%/4)",
      height: "100%",
    },
  },
  card: {
    height: "100%",
    borderRadius: 5,
    boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
  },
  img: {
    // width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 270,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    padding: "0px 10px",
    marginTop: 5,
    justifyContent: "space-between",
    height: 105,
  },
  txtStockTersedia: {
    fontSize: 12,
    color: "#777777",
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
  },
  txtStock: {
    fontSize: 11,
    "@media (max-width: 360px)": {
      fontSize: 9,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 14,
    // textAlign: "center",
    color: "#333333",
    // maxWidth: "80%",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    "@media(min-width: 280px)": {
      fontSize: 12,
    },
    "@media(min-width: 320px)": {
      fontSize: 12,
    },
    "@media(min-width: 360px)": {
      fontSize: 14,
    },
    "@media(min-width: 960px)": {
      fontSize: 14,
    },
  },
  txtLabel: {
    fontSize: 12,
    color: "#FFF",
  },
  label: {
    position: "absolute",
    boxShadow: "2px 2px darkslategrey",
    right: "5px",
    backgroundColor: "#e82868",
    lineHeight: "20px",
    color: "#fff",
    display: "inline-block",
    padding: "5px 5px",
    borderRadius: "0px 0px 20px 20px",
    marginBottom: 4,
  },
  price: {
    fontWeight: 700,
    fontSize: 12,
    color: "#eb6669",
    // textAlign: "center",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(max-width: 280px)": {
      // width: 100,
    },
    "@media(min-width: 960px)": {
      // maxWidth: 150,
    },
  },
  btnWrap: {
    margin: 8,
    marginBottom: 12,
  },
  btn: {
    color: "#ffff",
    fontWeight: 700,
    fontSize: 12,
    "@media(max-width: 280px)": {
      padding: "4px 4px",
    },
    textTransform: "capitalize",
  },
  empty: {
    backgroundColor: "hsla(0,0%,100%,.9)",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  txtEmpty: {
    fontSize: 18,
    fontWeight: 700,
    color: "rgba(0,0,0,.4)",
  },
  labelFlash: {
    backgroundColor: "#bde7ff6e",
    fontSize: 10,
    lineHeight: "20px",
    color: "#0088d6f7",
    display: "inline-block",
    padding: "2px 8px",
    fontWeight: "bold",
    borderRadius: 12,
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    minWidth: 80,
    // "@media(min-width: 280px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 320px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 360px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 960px)": {
    //   fontSize: 11,
    //   padding: "0 4px",
    // },
  },
  labelNew: {
    backgroundColor: "#E0FFDF",
    fontSize: 10,
    lineHeight: "20px",
    color: "#70A96F",
    display: "inline-block",
    padding: "2px 8px",
    fontWeight: "bold",
    borderRadius: 12,
    marginBottom: 5,
    marginTop: 5,
    // "@media(min-width: 280px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 320px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 360px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 960px)": {
    //   fontSize: 11,
    //   padding: "0 4px",
    // },
  },
  labelPre: {
    backgroundColor: "#FFF1E3",
    fontSize: 10,
    lineHeight: "20px",
    color: "#D2965E",
    display: "inline-block",
    padding: "2px 8px",
    fontWeight: "bold",
    borderRadius: 12,
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    minWidth: 65,
    // "@media(min-width: 280px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 320px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 360px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 960px)": {
    //   fontSize: 11,
    //   padding: "0 4px",
    // },
  },
}));

export default useStyles;
