import React from "react";

// material-ui
import Container from "@material-ui/core/Container";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
// Styles
import useStyles from "./styles";

export default function SkeletonLoading() {
  const classes = useStyles();

  return (
    <Container
      maxWidth="md"
      classes={{ root: classes.container }}
      className={classes.mainContainer}
    >
      <div>
        <Skeleton variant="rect" width="100%" height={300} />

        <div className={classes.gridRoot}>
          <div className={classes.wrapper}>
            <Card className={classes.card}>
              <Skeleton variant="rect" width="100%" height={200} />
              <div className={classes.intoSke}>
                <Typography component="div" variant="body1">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="caption">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="h3">
                  <Skeleton />
                </Typography>
              </div>
            </Card>
          </div>
          <div className={classes.wrapper}>
            <Card className={classes.card}>
              <Skeleton variant="rect" width="100%" height={200} />
              <div className={classes.intoSke}>
                <Typography component="div" variant="body1">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="caption">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="h3">
                  <Skeleton />
                </Typography>
              </div>
            </Card>
          </div>
          <div className={classes.wrapper}>
            <Card className={classes.card}>
              <Skeleton variant="rect" width="100%" height={200} />
              <div className={classes.intoSke}>
                <Typography component="div" variant="body1">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="caption">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="h3">
                  <Skeleton />
                </Typography>
              </div>
            </Card>
          </div>
          <div className={classes.wrapper}>
            <Card className={classes.card}>
              <Skeleton variant="rect" width="100%" height={200} />
              <div className={classes.intoSke}>
                <Typography component="div" variant="body1">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="caption">
                  <Skeleton />
                </Typography>
                <Typography component="div" variant="h3">
                  <Skeleton />
                </Typography>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Container>
  );
}
