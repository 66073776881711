import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        width: 960,
        position: 'fixed',
        bottom: 0,
        margin: '0 auto',
        borderTop: 'solid 1px #ddd',
        zIndex: 999,
        backgroundColor: '#fff',
        paddingTop: 5,
    },
    action: {
        fontSize: 12
    }
}))

export default useStyles