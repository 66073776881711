import numeral from "numeral";

numeral.register("locale", "id", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "RB",
    million: "JT",
    billion: "M",
    trillion: "T",
  },
  currency: {
    symbol: "Rp. ",
  },
});

numeral.locale("id");

export const currency = (number) => {
  return numeral(number).format("$0,0");
};

export const delimiters = (number) => {
  return numeral(number).format("0,0");
};

export function arrayToCurrencyString(arr) {
  let harga_str = "";
  if (!Array.isArray(arr)) {
    return currency(arr);
  }
  if (arr.length <= 0) {
    harga_str = currency(0);
  } else if (arr.length === 1) {
    harga_str = currency(arr[0]);
  } else if (arr.length > 1) {
    const min = Math.min(...arr);
    const max = Math.max(...arr);
    if (min === max) {
      harga_str = currency(min);
    } else {
      harga_str = `${currency(Math.min(...arr))} - ${currency(
        Math.max(...arr)
      )}`;
    }
  }
  return harga_str;
}

export function calculatePromo(harga, promo) {
  if (!promo) {
    return harga;
  } else {
    if (promo.tipe === "%") {
      const discount = harga * (promo.nilai / 100);
      return harga - discount;
    } else {
      return harga - promo.nilai;
    }
  }
}

export function arrayToCurrencyStringPromo(arr, promo) {
  let harga_str = "";
  if (!Array.isArray(arr)) {
    return currency(calculatePromo(arr, promo));
  }

  if (arr.length <= 0) {
    harga_str = currency(0);
  } else if (arr.length === 1) {
    harga_str = currency(calculatePromo(arr[0], promo));
  } else if (arr.length > 1) {
    const min = Math.min(...arr);
    const max = Math.max(...arr);
    if (min === max) {
      harga_str = currency(calculatePromo(min, promo));
    } else {
      harga_str = `${currency(
        calculatePromo(Math.min(...arr), promo)
      )} - ${currency(calculatePromo(Math.max(...arr), promo))}`;
    }
  }
  return harga_str;
}
