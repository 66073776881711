import React, { useEffect, useRef, useState, Suspense } from "react";

// Context & Component's
import {
  useFirebase,
  Header,
  useData,
  analytics,
  firestore,
  SkeletonProduct,
  Divider,
  Gap,
} from "../../components";
// import ProductFilter from "./ProductFilter";
// import DrawerFilter from "./DrawerFilter";

// Styles
import useStyles from "./styles";

// Router
import { useHistory } from "react-router-dom";

// React-Helmet
import { Helmet } from "react-helmet";

import InfiniteScroll from "react-infinite-scroll-component";
import FlashSale from "./flashSale";
import Campaigns from "./campaigns";
import NewProduk from "./newProduk";
import PreOrder from "./preOrder";
import { Typography } from "@material-ui/core";
// import array_chunk from "lodash/chunk";
const ProductKatalog = React.lazy(() => import("./ProductKatalog"));

export default function Katalog() {
  const { subdomain } = useFirebase();

  const { userToko, uid, campaigns, products, setProducts, preorderUtils } =
    useData();

  const history = useHistory();

  const classes = useStyles();

  const toko = `Toko ${subdomain.charAt(0).toUpperCase() + subdomain.slice(1)}`;

  // const [dialog, setDialog] = useState({
  //   open: false,
  //   mode: "",
  // });
  // const { products } = useProduct();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(true);
  const [productIds, setProductIds] = useState([]);
  const lastProduct = useRef(12);
  const allProducts = useRef(false);
  const [productIDsEffect, setProductIDsEffect] = useState(true);

  const loadMoreProducts = async function () {
    // console.log("ini jalan");
    setLoadingMore(true);
    try {
      const start = lastProduct.current - 12;
      const end = lastProduct.current;
      if (!allProducts.current) {
        if (end >= productIds?.length) {
          allProducts.current = true;
        } else {
          lastProduct.current += 12;
        }
        const query = productIds.slice(start, end);
        const newProducts = await Promise.all(
          query.map(async (productId) => {
            const doc = await firestore
              .collection("products")
              .doc(productId)
              .get();
            if (doc.exists) {
              const variants = await firestore
                .collection("products")
                .doc(productId)
                .collection("variants")
                .where(`harga_normal`, ">", 0)
                .get();
              return {
                id: productId,
                variants: variants.docs.map((variant) => {
                  return {
                    id: variant.id,
                    ...variant.data(),
                  };
                }),
                ...doc.data(),
              };
            }
            return null;
          })
        );
        const filteredProducts = newProducts.filter((product) => {
          const productDp = !preorderUtils.down_payment_products?.includes(
            product.id
          );
          return product.variants?.length > 0 && !product?.sembunyikan_produk && productDp;
        });
        setProducts((products) => [...products, ...filteredProducts]);
        lastProduct.current = query.docs[query.docs?.length - 1];
      } else {
        setLoadingMore(false);
      }
    } catch (e) {
      console.log(e.message);
      // setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (productIds?.length && productIDsEffect && !products?.length) {
      setProductIDsEffect(false);
      setLoading(true);
      lastProduct.current = 12;
      allProducts.current = false;
      setProducts([]);
      const loadProducts = async function () {
        try {
          const start = lastProduct.current - 12;
          const end = lastProduct.current;
          if (!allProducts.current) {
            if (end >= productIds?.length) {
              allProducts.current = true;
            } else {
              lastProduct.current += 12;
            }
            const query = productIds.slice(start, end);
            const newProducts = await Promise.all(
              query.map(async (productId) => {
                const doc = await firestore
                  .collection("products")
                  .doc(productId)
                  .get();
                if (doc.exists) {
                  const variants = await firestore
                    .collection("products")
                    .doc(productId)
                    .collection("variants")
                    .where(`harga_normal`, ">", 0)
                    .get();
                  return {
                    id: productId,
                    variants: variants.docs.map((variant) => {
                      return {
                        id: variant.id,
                        ...variant.data(),
                      };
                    }),
                    ...doc.data(),
                  };
                }
                return null;
              })
            );
            const filteredProducts = newProducts.filter((product) => {
              const productDp = !preorderUtils.down_payment_products?.includes(
                product.id
              );

              return (
                product.variants?.length > 0 &&
                !product?.sembunyikan_produk &&
                productDp
              );
            });
            setProducts((products) => [...products, ...filteredProducts]);
            lastProduct.current = query.docs[query.docs?.length - 1];
          } else {
            setLoading(false);
          }
        } catch (e) {
          console.log(e.message);
          setLoading(false);
        }
      };
      loadProducts();
    }
  }, [lastProduct, allProducts, productIds, productIDsEffect]);

  useEffect(() => {
    if (userToko?.products) {
      if (userToko?.products?.length) {
        setProductIds([...userToko?.products].reverse());
      } else {
        setLoading(false);
      }
    }
  }, [userToko?.products]);

  const viewCart = () => {
    analytics.logEvent("view_cart", { user_id: uid });

    history.push("/cart");
  };

  const onFocus = () => {
    history.push("/browse");
  };

  useEffect(() => {
    const toko = window.location.host;

    analytics.logEvent("toko_visited", { domain: toko });
  }, []);
  // console.log(products);
  return (
    <>
      <Helmet>
        <title>{toko}</title>
        <meta name="description" content={toko} />
        <meta property="og:title" content={toko} />
        <meta property="og:description" content={toko} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={userToko.cover} />
        <meta property="og:site_name" content={toko} />
        <meta property="og:url" content={`${window.location.origin}`} />
      </Helmet>
      <Header placeholder={toko} onClick={viewCart} onFocus={onFocus} />
      <div style={{ height: 300 }}>
        {userToko.cover ? (
          <img
            loading="lazy"
            className={classes.hero}
            src={userToko.cover}
            alt="img"
          />
        ) : (
          <img
            loading="lazy"
            className={classes.hero}
            src="https://firebasestorage.googleapis.com/v0/b/sommerce-develop.appspot.com/o/tokorame%2FBanner%20Tokorame%20(1).jpg?alt=media&token=9a303e3d-3664-4afa-a6cf-b9d178e282bf"
            alt="img"
          />
        )}
      </div>
      <FlashSale />
      <NewProduk />
      <PreOrder />
      {campaigns?.length > 0 && (
        <>
          <Campaigns />
        </>
      )}
      <Gap height={10} />
      <Divider />
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          textTransform: "uppercase",
          margin: 10,
        }}
      >
        Semua Produk
      </Typography>
      <InfiniteScroll
        dataLength={products?.length}
        style={{ overflow: "hidden", marginBottom: "60px" }}
        next={loadMoreProducts}
        hasMore={loadingMore}
        loader={
          <div>{products?.length === 0 ? null : <SkeletonProduct />}</div>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! Yay! Kamu Sudah Melihat Semua</b>
          </p>
        }
      >
        {loading ? (
          <>
            <SkeletonProduct />
          </>
        ) : (
          <Suspense fallback={<SkeletonProduct />}>
            <div className={classes.productContent}>
              <ProductKatalog products={products} loading={loading} />
            </div>
          </Suspense>
        )}
      </InfiniteScroll>
    </>
  );
}
