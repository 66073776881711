import React, { useState, useEffect } from "react";
// Material UI
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { ArrowBack, Search, ShoppingCart } from '@material-ui/icons'

// Icon's
import SearchIcon from "@material-ui/icons/Search";

// Styles
import useStyles from "./styles";

// Components
import { Gap } from "../../atoms";

function WithSearchBack({ onClick, value, placeholder, onChange }) {

  const classes = useStyles()

  return (
      <div
          className={classes.root}
      >
          <IconButton
              size="small"
              onClick={onClick}
          >
              <ArrowBack className={classes.icon} />
          </IconButton>
          <Gap width={16} />
          <div className={classes.searchBox}>
              <Search className={classes.icSearch} />
              <Gap width={10} />
              <InputBase
                  placeholder={placeholder}
                  value={value}
                  autoFocus
                  inputProps={{ 'aria-label': placeholder }}
                  onChange={onChange}
                  fullWidth
              />
          </div>
          <Gap width={16} />
      </div>
  )
}


function Headerkatalog({
  placeholder,
  value,
  type,
  title,
  onClick,
  onChange,
  onFocus,
  autoFocus,
}) {
  const classes = useStyles();
  const [changeColor, setChangeColor] = useState(false);

  

  useEffect(() => {
    const changeNavbar = () => {
      if (window.scrollY >= 200) {
        setChangeColor(true);
      } else {
        setChangeColor(false);
      }
    };

    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);

  if (type === "with-back") {
    return (
      <WithSearchBack
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        value={value}
      />
    );
  }

  return (
    <div
      className={classes.root}
      style={
        changeColor
          ? {
              backgroundColor: "#ffff",
              boxShadow: "0px 15px 10px -15px rgba(0,0,0,0.3)",
            }
          : null
      }
    >
      <div className={classes.searchBox}>
        <SearchIcon className={classes.icSearch} />
        <Gap width={10} />
        <InputBase
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          inputProps={{ "aria-label": placeholder }}
          fullWidth
          onFocus={onFocus}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  );
}

export default Headerkatalog;
