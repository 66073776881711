import React, { useEffect, useState } from "react";
import { ILProfpic, ILWa } from "../../../assets";
import { injectStyle } from "../../../utils";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
// import { firestore, useFirebase } from "../../context";
// import { useDocumentData } from "react-firebase-hooks/firestore";

function HelpBarKatalog() {
  // const { subdomain, subdomainDetail } = useFirebase();
  // console.log(subdomainDetail);
  // const tokoDoc =
  //   subdomainDetail !== undefined
  //     ? firestore.doc(`webstores/${subdomainDetail?.user_id}`)
  //     : null;

  // const [userToko] = useDocumentData(tokoDoc);

  // const toko = `${subdomain.charAt(0).toUpperCase() + subdomain.slice(1)}`;

  // const { userToko } = useData();

  // const wa = userToko && userToko?.wa.replace(/-|[+]/gm, "");

  const [open, setOpen] = useState(false);

  const keyframesStyle = `
    @keyframes tuingtuing {
      0%   { transform: scale(1); }
      50%  { transform: scale(1.2); }
      100% { transform: scale(1); }
    }
  `;
  injectStyle(keyframesStyle);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [setOpen]);

  const expand = open === true && {
    background: "#f9f9f9",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,.2)",
    width: 232,
  };

  const bodyExpand = open === true && {
    transform: "scale(1)",
    opacity: 1,
  };

  return (
    <div>
      <div
        style={{
          zIndex: 100,
          position: "fixed",
          bottom: 60,
          right: 0,
          width: 76,
          borderRadius: "52px 0 0 52px",
          boxShadow: "0 2px 10px 0px transparent",
          padding: 10,
          overflow: "hidden",
          transition: "all .4s ease",
          ...expand,
        }}
      >
        <div
          onClick={() => setOpen(true)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            width: 56,
            height: 56,
            flex: "0 0 auto",
            transition: "all .2s ease",
            position: "relative",
            backgroundColor: "#FF1493",
            border: "1px solid #FF1493",
            boxShadow: "4px 2px 10px 0 rgba(0,0,0,.1) ",
          }}
        >
          <span
            style={{
              display: "block",
              width: 52,
              height: 52,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundSize: "auto 52px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50%",
              backgroundImage: `url(${ILProfpic})`,
            }}
          ></span>
        </div>
        <div
          style={{
            transition: "all .4s ease",
            transformOrigin: "center right",
            transform: "scale(0)",
            position: "absolute",
            bottom: 10,
            right: 12,
            opacity: 0,
            ...bodyExpand,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: 6,
            }}
          >
            <div
              style={{
                flex: 1,
                fontSize: 15,
                color: "#333",
                fontWeight: 700,
                lineHeight: "20px",
              }}
            >
              Ada Pertanyaan?
            </div>
            <IconButton
              size="small"
              onClick={() => setOpen(false)}
              style={{
                display: "flex",
                color: "#aaa",
                position: "relative",
                top: -8,
                right: -6,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://wa.me/6281268000433?text=Halo kak, Saya ingin bertanya seputar tokorame`}
            style={{
              height: 24,
              border: "1px solid #00a441",
              borderRadius: 4,
              backgroundSize: "12px 12px",
              backgroundImage: `url(${ILWa})`,
              paddingLeft: 27,
              fontSize: 10,
              lineHeight: "15px",
              color: "#00a441",
              fontWeight: 400,
              boxShadow: "none",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "0 16px",
              backgroundPosition: 10,
              whiteSpace: "nowrap",
              transition: "all .3s ease",
              backgroundRepeat: "no-repeat",
              textDecoration: "none",
              outline: "none",
            }}
          >
            <b> &nbsp;&nbsp;&nbsp; Chat dengan Admin </b>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HelpBarKatalog;
