import React from "react";

// Router
import Router from "./router";

// Firebase Context
import { FirebaseProvider } from "./components";

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

// Notistack
import { SnackbarProvider } from "notistack";

// React-Helmet
import { Helmet } from "react-helmet";

// Config
import { MidtransClientKey, MidtransUrl } from "./config";
import ReactPixel from "react-facebook-pixel";

// Theme
const webStoreTheme = createMuiTheme(theme);
if (process.env.REACT_APP_FIREBASE_PROD === "YES" && "https://mysure.tokorame.store/" == window.location.href) {
  // const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  ReactPixel.init("1748938445607214");
  ReactPixel.pageView(); // For tracking page view
  // const options = {
  //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: false, // enable logs
  // };
  // console.log(window.location.href);
}

function App() {
  return (
    <>
      <Helmet>
        <script
          src={MidtransUrl}
          data-client-key={MidtransClientKey}
          type="text/javascript"
        />
      </Helmet>
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <CssBaseline />
        <MuiThemeProvider theme={webStoreTheme}>
          <div className="App" style={{ backgroundColor: "#f0f0f0" }}>
            <FirebaseProvider>
              <Router />
            </FirebaseProvider>
          </div>
        </MuiThemeProvider>
      </SnackbarProvider>
    </>
  );
}

export default App;
