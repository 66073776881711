import React, { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

// React Router
import { Route, Redirect } from "react-router-dom";

// Context
import { useFirebase, DataProvider, firestore, ProductProvider } from "../../components";

// Component
import { Progress } from "../../components/";
import LayoutKatalog from "../../pages/LayoutKatalog";

export default function MainRoute({ component: Component, ...restProps }) {
  const [subdomain] = useState(window.location.hostname.split("."));

  const { subdomainDetail } = useFirebase();
  const webstoreDoc = firestore.doc(`webstores/${subdomainDetail?.user_id}`);
  const [webstore, roleLoading, error] = useDocumentData(webstoreDoc);
  const levelRef = firestore.doc(`levels/${subdomainDetail?.user_id}`);
  const [levels, loadingLevels, errorLev] = useDocumentData(levelRef);
  // console.log("levels", levels?.is_active);

  if (loadingLevels || roleLoading) {
    return <Progress />;
  }

  return (
    <Route
      {...restProps}
      render={(props) => {
        return subdomain.length <= 2 || subdomain[0] === "www" ? (
          <ProductProvider>
            <LayoutKatalog />
          </ProductProvider>
        ) : subdomain.length >= 3 &&
          subdomainDetail !== undefined &&
          !webstore?.isBanned &&
          levels?.is_active ? (
          <DataProvider>
            {/* <ProductProvider> */}
            <Component {...props} />
            {/* </ProductProvider> */}
          </DataProvider>
        ) : (
          <Redirect
            to={{
              pathname: "/404-rna",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
}
