import React, { useState, useEffect } from "react";
// import { useData } from "../../../components/firebase/DataProvider";
// import { firefunctions } from "../../components/firebase/FirebaseProvider";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import ScrollContainer from "react-indiana-drag-scroll";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import { arrayToCurrencyString } from "../../../utils/currency";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import Dompet from "../../assets/icon/dompet.png";
import Timer from "../../assets/icon/timer.png";
import Divider from "@material-ui/core/Divider";
import MoneyWings from "../../assets/icon/moneyWings";

import useStyles from "./styles/campaignsStyle";
import { functions, Gap, useData } from "../../components";
import { arrayToCurrencyString } from "../../utils";

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

const ReadMore = ({ children }) => {
  const classes = useStyles();
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Typography className={classes.textDesc}>
      {isReadMore ? text?.slice(0, 150) : text}
      <span onClick={toggleReadMore} className={classes.showMore}>
        {isReadMore ? "...lebih banyak" : " lebih sedikit"}
      </span>
    </Typography>
  );
};

function DialogCampaigns({
  data,
  open,
  handleClose,
  awal_periode,
  akhir_periode,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.wrapDialog}>
          <div>
            <div className={classes.wrapButtonClose}>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.wrapHero}>
              <img
                loading="lazy"
                className={classes.hero}
                src={data?.thumbnail}
                alt={data?.nama}
              />
            </div>
          </div>

          <div className={classes.wrapContent}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.wrapIcon}>
                  <img src={Dompet} alt="dompet" className={classes.icon} />
                </div>
                <Gap width={10} />
                <div>
                  <Typography component="h5">
                    {arrayToCurrencyString(data?.data?.nilai)}
                  </Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    Total {data?.tipe}
                  </Typography>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.wrapIcon}>
                  <img src={Timer} alt="timer" className={classes.icon} />
                </div>
                <Gap width={10} />

                <div>
                  <Typography component="h5">{akhir_periode}</Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    Periode {data?.tipe}
                  </Typography>
                </div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                paddingTop: 10,
              }}
            >
              <div>
                <Typography component="h4">{data?.nama}</Typography>
              </div>
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <ReadMore>{data?.deskripsi}</ReadMore>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function Campaigns() {
  const classes = useStyles();
  const { campaigns } = useData();
  const [data, setData] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    data: null,
    awal_periode: null,
    akhir_periode: null,
  });
  useEffect(() => {
    if (campaigns) {
      Promise.all(
        campaigns.map(async (doc) => {
          const awal_dates = new Date(doc?.begins_at?.seconds * 1000);
          const sendToBackEnd_start_date = `${awal_dates.getFullYear()}-${pad2(
            awal_dates.getMonth() + 1
          )}-${pad2(awal_dates.getDate())}`;
          const akhir_dates = new Date(doc?.end_at?.seconds * 1000);

          const sendToBackEnd_end_date = `${akhir_dates.getFullYear()}-${pad2(
            akhir_dates.getMonth() + 1
          )}-${pad2(akhir_dates.getDate())}`;

          const func = functions.httpsCallable("getCampaignValue");
          const data = {
            campaign_id: doc?.id,
            start_date: sendToBackEnd_start_date,
            end_date: sendToBackEnd_end_date,
          };
          const newData = await func(data);

          return {
            ...doc,
            ...newData,
          };
        })
      ).then((hasil) => setData(hasil));
    } else {
      setData([]);
    }
  }, [campaigns]);

  return (
    <>
      <Paper elevation={0} className={classes.container}>
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "bold",
            textTransform: "uppercase",
            color: "white",
            margin: "10px 0px",
          }}
        >
          Campaigns
        </Typography>{" "}
        <ScrollContainer className={classes.wrapScroll}>
          {!data?.length > 0 ? (
            <>
              <Card style={{ minWidth: 275, margin: 5 }}>
                <div style={{ textAlign: "center", padding: 15 }}>
                  <Typography variant="h5" style={{ color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                </div>
              </Card>
              <Card style={{ minWidth: 275, margin: 5 }}>
                <div style={{ textAlign: "center", padding: 15 }}>
                  <Typography variant="h5" style={{ color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                </div>
              </Card>
              <Card style={{ minWidth: 275, margin: 5 }}>
                <div style={{ textAlign: "center", padding: 15 }}>
                  <Typography variant="h5" style={{ color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#5675D3" }}>
                    <Skeleton variant="text" />
                  </Typography>
                </div>
              </Card>
            </>
          ) : (
            data?.map((value) => {
              const awal_dates = new Date(value?.begins_at?.seconds * 1000);
              const akhir_dates = new Date(value?.end_at?.seconds * 1000);
              const awal_periode = `${pad2(awal_dates.getDate())}/${pad2(
                awal_dates.getMonth() + 1
              )}/${awal_dates.getFullYear()}`;
              const akhir_periode = `${pad2(akhir_dates.getDate())}/${pad2(
                akhir_dates.getMonth() + 1
              )}/${akhir_dates.getFullYear()}`;

              return (
                <Card style={{ minWidth: 275, margin: 5 }}>
                  <CardActionArea
                    onClick={() =>
                      setDialog({
                        open: true,
                        data: value,
                        akhir_periode: akhir_periode,
                        awal_periode: awal_periode,
                      })
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 15,
                      }}
                    >
                      <div>
                        <Typography variant="h5" style={{ color: "#5675D3" }}>
                          {arrayToCurrencyString(value?.data?.nilai)}
                        </Typography>
                        <Typography
                          style={{ fontSize: "12px", color: "#5675D3" }}
                        >
                          {value?.nama}
                        </Typography>
                        <Typography
                          style={{ fontSize: "12px", color: "#5675D3" }}
                        >
                          {awal_periode} - {akhir_periode}
                        </Typography>
                      </div>
                      <div>
                        <MoneyWings />
                      </div>
                    </div>
                  </CardActionArea>
                </Card>
              );
            })
          )}
        </ScrollContainer>
      </Paper>
      <DialogCampaigns
        data={dialog.data}
        open={dialog.open}
        awal_periode={dialog.awal_periode}
        akhir_periode={dialog.akhir_periode}
        handleClose={() => setDialog({ open: false, data: null })}
      />
    </>
  );
}

export default Campaigns;
