import React, { useState, useEffect } from "react";

// Material UI
import InputBase from "@material-ui/core/InputBase";

// Icon's
import SearchIcon from "@material-ui/icons/Search";

// Styles
import useStyles from "./styles";

// Components
import { Gap } from "../../atoms";

function WithNoCart({ placeholder, value, onChange }) {
  const [changeColor, setChangeColor] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const changeNavbar = () => {
      if (window.scrollY >= 200) {
        setChangeColor(true);
      } else {
        setChangeColor(false);
      }
    };

    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);

  return (
    <div
      className={classes.root}
      style={
        changeColor
          ? {
              backgroundColor: "#ffff",
              boxShadow: "0px 15px 10px -15px rgba(0,0,0,0.3)",
            }
          : null
      }
    >
      <div className={classes.searchBox}>
        <SearchIcon className={classes.icSearch} />
        <Gap width={10} />
        <InputBase
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          inputProps={{ "aria-label": placeholder }}
          fullWidth
        />
      </div>
    </div>
  );
}

export default WithNoCart;
