import React, { useState, useEffect } from "react";
import slugify from "slugify";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles/newProdukStyle";
import ScrollContainer from "react-indiana-drag-scroll";
import Button from "@material-ui/core/Button";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import { Card, Typography, CardActionArea, Divider } from "@material-ui/core";
import { useHistory } from "react-router";
import { analytics, firestore, useData } from "../../components";
import {
  arrayToCurrencyString,
  arrayToCurrencyStringPromo,
  findOnePromo,
} from "../../utils";
import DialogNewProduk from "./dialogNewProduk";

function PreOrder() {
  const classes = useStyles();
  const history = useHistory();
  const [products, setProducts] = useState([]);
  // const [slicedProducts, setSlicedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { level, promo, global_komisi, userToko, preorderUtils } = useData();

  useEffect(() => {
    async function getProducts() {
      setLoading(true);
      try {
        // console.log("LEWAT");
        const productsRef = firestore.collection("products");
        const query = await productsRef
          .where("sembunyikan_produk", "==", false)
          .where("properties.type", "==", "preorder")
          .where(
            "properties.end_at",
            ">",
            new Date(Date.now() - 60 * 60 * 1000)
          )
          .get();
        if (!query.empty) {
          const newProducts = await Promise.all(
            query.docs.map(async (product) => {
              const variants = await product.ref
                .collection("variants")
                .where(`harga_normal`, ">", 0)
                .get();
              return {
                id: product.id,
                ...product.data(),
                variants: variants.docs.map((variant) => {
                  return {
                    id: variant.id,
                    ...variant.data(),
                  };
                }),
              };
            })
          );
          const filteredProducts = newProducts.filter((product) => {
            const stok = product?.variants?.map(
              (variant) => variant?.stok_available
            );
            const total_stok = stok?.reduce((val, nilaiSekarang) => {
              return val + nilaiSekarang;
            }, 0);
            return product.variants.length > 0 && total_stok > 0;
          });
          let filterArr = filteredProducts.filter((item) => {
            const productUser = userToko.products.includes(item.id);
            const productDp = !preorderUtils.down_payment_products?.includes(item.id)
            return productUser && productDp;
          });
          const filter = filterArr.length > 0 ? filterArr : [];
          setProducts(filter);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    getProducts();
  }, [userToko.products]);

  const toDetailProduct = (id, title) => () => {
    analytics.logEvent("view_product", { name: title, id: id });

    history.push(`/produk/${slugify(title.toLowerCase())}/${id}`);
  };

  return !loading && products?.length ? (
    <div style={{ marginTop: 5 }}>
      <Divider style={{ height: 5 }} />
      <Paper elevation={0} className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Typography
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            PRE ORDER
          </Typography>
          <div
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            <Button
              size="small"
              endIcon={<ArrowRightIcon />}
              color="primary"
              onClick={() => setOpen(true)}
            >
              <Typography style={{ fontSize: "11px" }}>Lihat semua</Typography>
            </Button>
            <DialogNewProduk
              open={open}
              handleClose={() => setOpen(false)}
              data={products}
              mode="Pre Order"
            />
          </div>
        </div>
        <ScrollContainer className={classes.wrapScroll}>
          {products
            .sort((a, b) => {
              const stok_a = a?.variants?.map(
                (variant) => variant?.stok_available
              );
              const total_stok_a = stok_a?.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);
              const stok_b = b?.variants?.map(
                (variant) => variant?.stok_available
              );
              const total_stok_b = stok_b?.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);
              return total_stok_b - total_stok_a;
            })
            .map((item, index) => {
              const stok = item.variants.map(
                (variant) => variant.stok_available
              );

              const harga = item.variants.map(
                (variant) => variant[`harga_normal`]
              );

              const harga_normal = item.variants.map(
                (variant) => variant?.harga_normal
              );

              const promoFound = findOnePromo(item, promo);

              const total_stok = stok.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);

              const emptyStock = total_stok === 0 ? true : false;

              const harga_str = arrayToCurrencyString(harga);

              const harga_str_promo =
                promoFound &&
                arrayToCurrencyStringPromo(
                  harga_normal,
                  promoFound,
                  level?.level,
                  global_komisi
                );

              const textBadgePromo = (() => {
                let text = "";

                if (promoFound) {
                  if (promoFound?.tipe === "%") {
                    text = `${promoFound?.nilai}%`;
                  } else {
                    text = `${promoFound?.nilai / 1000}K`;
                  }
                }

                return text;
              })();
              if (index < 5) {
                return (
                  <div className={classes.wrapper} key={index}>
                    <Card className={classes.card}>
                      <CardActionArea
                        onClick={toDetailProduct(item.id, item.nama)}
                      >
                        {promoFound && !emptyStock ? (
                          <div className={classes.label}>
                            <Typography className={classes.txtLabel}>
                              {textBadgePromo}
                            </Typography>
                            <Typography className={classes.txtLabel}>
                              OFF
                            </Typography>
                          </div>
                        ) : null}
                        {emptyStock ? (
                          <div className={classes.empty}>
                            <span className={classes.txtEmpty}>Stok Habis</span>
                          </div>
                        ) : null}
                        {item.thumbnail?.length > 0 ? (
                          <div
                            loading="lazy"
                            // src={item.thumbnail[0]}
                            alt={item.id}
                            className={classes.img}
                            style={{
                              backgroundImage: `url(${item?.thumbnail?.[0]})`,
                            }}
                          />
                        ) : (
                          <div
                            loading="lazy"
                            // src={item.thumbnail}
                            alt={item.id}
                            className={classes.img}
                            style={{
                              backgroundImage: `url(${item?.thumbnail})`,
                            }}
                          />
                        )}
                      </CardActionArea>
                      {/* <div className={classes.contentWrapper}> */}
                      <div className={classes.content}>
                        <div style={{ display: "flex" }}>
                          <div className={classes.labelPre}>Pre Order</div>
                        </div>{" "}
                        <Typography className={classes.title} component="p">
                          {item?.nama}
                        </Typography>
                        <div>
                          {item?.properties?.type === "preorder" ? (
                            ""
                          ) : (
                            <>
                              <Typography
                                className={classes.txtStockTersedia}
                                component="p"
                              >
                                Stok tersedia
                              </Typography>
                              <Typography
                                className={classes.txtStock}
                                component="p"
                              >
                                {total_stok > 100 ? "99+" : total_stok} pcs
                              </Typography>
                            </>
                          )}
                        </div>
                        <Typography className={classes.price} component="p">
                          {promoFound ? harga_str_promo : harga_str}
                        </Typography>
                      </div>
                      <div className={classes.btnWrap}>
                        {emptyStock ? (
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={classes.btn}
                            style={{
                              backgroundColor: "rgb(255, 107, 107)",
                            }}
                          >
                            Stok Habis
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.btn}
                            onClick={toDetailProduct(item.id, item.nama)}
                          >
                            Beli
                          </Button>
                        )}
                      </div>
                      {/* </div> */}
                    </Card>
                  </div>
                );
              }
              return null;
            })}
          {products?.length > 3 ? (
            <div
              style={{
                alignSelf: "center",
                paddingLeft: 37.5,
                paddingRight: 37.5,
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <div style={{ width: 80 }}>
                <PlayCircleOutlineIcon
                  color="primary"
                  style={{ width: "100%", fontSize: 27.5 }}
                />
                <Typography
                  style={{ fontSize: 12.5, marginTop: 10 }}
                  color="primary"
                  align="center"
                >
                  Lihat Semua
                </Typography>
              </div>
            </div>
          ) : null}{" "}
        </ScrollContainer>
      </Paper>
    </div>
  ) : null;
}

export default PreOrder;
