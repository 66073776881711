import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import slugify from "slugify";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SpesialOfferIcon from "../../assets/icon/spesial-offer.svg";
import FlashSaleIcon from "../../assets/icon/flashSaleIcon.svg";
import useStyles from "./styles/flashSaleStyle";
import { analytics, useData } from "../../components";
import {
  arrayToCurrencyString,
  arrayToCurrencyStringPromo,
  findOnePromo,
} from "../../utils";
import Countdown from "react-countdown";
import { DialogTitle, IconButton } from "@material-ui/core";
import ScrollContainer from "react-indiana-drag-scroll";

function DialogFlashsale({ open, handleClose, data, keywords, timer, mode }) {
  const classes = useStyles();
  const history = useHistory();
  const { level, promo, global_komisi } = useData();
  const [selected, setSelected] = useState("all");
  const [products, setProducts] = useState(data);
  const firstMount = useRef(true);

  useEffect(() => {
    if (!firstMount.current) {
      if (selected === "all") {
        setProducts(data);
      } else {
        const filteredProducts = data?.filter(
          (elm) => elm?.kategori1?.id === selected
        );
        setProducts(filteredProducts);
      }
    } else {
      firstMount.current = false;
    }
  }, [selected]);

  const toDetailProduct = (id, title) => () => {
    // console.log("to detail...");
    analytics.logEvent("view_product", { name: title, id: id });

    history.push(`/produk/${slugify(title.toLowerCase())}/${id}`);
  };

  const Renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <>
        <div
          style={{
            borderRadius: "4px",
            padding: "0px 5px",
            backgroundColor: "rgb(239, 241, 254)",
          }}
        >
          <Typography color="primary" style={{ fontSize: "13px" }}>
            {days > 9 ? days : `0${days}`}
          </Typography>
        </div>
        <Typography
          color="primary"
          style={{ fontSize: "13px", fontWeight: "bold", margin: "0px 2.5px" }}
        >
          :{" "}
        </Typography>
        <div
          style={{
            borderRadius: "4px",
            padding: "0px 5px",
            backgroundColor: "rgb(239, 241, 254)",
          }}
        >
          <Typography color="primary" style={{ fontSize: "13px" }}>
            {hours > 9 ? hours : `0${hours}`}
          </Typography>
        </div>
        <Typography
          color="primary"
          style={{ fontSize: "13px", fontWeight: "bold", margin: "0px 2.5px" }}
        >
          :{" "}
        </Typography>
        <div
          style={{
            borderRadius: "4px",
            padding: "0px 5px",
            backgroundColor: "rgb(239, 241, 254)",
          }}
        >
          <Typography color="primary" style={{ fontSize: "13px" }}>
            {minutes > 9 ? minutes : `0${minutes}`}
          </Typography>
        </div>
        <Typography
          color="primary"
          style={{ fontSize: "13px", fontWeight: "bold", margin: "0px 2.5px" }}
        >
          :{" "}
        </Typography>
        <div
          style={{
            borderRadius: "4px",
            padding: "0px 5px",
            backgroundColor: "rgb(239, 241, 254)",
          }}
        >
          <Typography color="primary" style={{ fontSize: "13px" }}>
            {seconds > 9 ? seconds : `0${seconds}`}
          </Typography>
        </div>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        style: { maxWidth: 960 },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            style={{ marginRight: 10 }}
            size="small"
            onClick={handleClose}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography style={{ fontSize: 17 }}>{mode}</Typography>
        </div>
      </DialogTitle>{" "}
      {/* <Header title="Flashsale" type="with-title" onClick={handleClose} /> */}
      <DialogContent dividers style={{ padding: "0px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#5675D3",
            color: "#fff",
          }}
        >
          {mode === "Flashsale" ? (
            <img src={FlashSaleIcon} alt="icon" style={{ width: "80px" }} />
          ) : mode === "Special Offers" ? (
            <img src={SpesialOfferIcon} alt="icon" style={{ width: "80px" }} />
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            color: "black",
            marginTop: 5,
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          <Typography style={{ fontSize: 15, marginRight: 20 }}>
            Berakhir Dalam{" "}
          </Typography>
          <Countdown date={timer} renderer={Renderer} />
        </div>{" "}
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            alignItems: "center",
            padding: "10px 10px",
            color: "black",
          }}
        >
          <Typography style={{ fontSize: 15, fontWeight: "bold" }}>
            Kategori Menarik Lainnya
          </Typography>
        </div>
        <ScrollContainer
          style={{
            display: "flex",
            overflowX: "auto",
            flexWrap: "nowrap",
            margin: "5px 10px",
          }}
        >
          {keywords?.map((item) => {
            return (
              <div style={{ marginRight: "10px" }}>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setSelected(item?.[1]);
                  }}
                  style={{ padding: "5px 10px", borderRadius: 12 }}
                >
                  <Typography
                    noWrap
                    color={item?.[1] === selected ? "primary" : ""}
                    style={{ textTransform: "capitalize", fontSize: 13 }}
                    variant="button"
                  >
                    {item?.[0]}
                  </Typography>
                </Button>
              </div>
            );
          })}
        </ScrollContainer>
        <Grid container className={classes.root}>
          {products
            .sort((a, b) => {
              const stok_a = a?.variants?.map(
                (variant) => variant.stok_available
              );
              const total_stok_a = stok_a?.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);
              const stok_b = b?.variants?.map(
                (variant) => variant?.stok_available
              );
              const total_stok_b = stok_b?.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);
              return total_stok_b - total_stok_a;
            })
            .map((item, index) => {
              const stok = item.variants.map(
                (variant) => variant.stok_available
              );

              const harga = item.variants.map(
                (variant) => variant[`harga_normal`]
              );

              const harga_normal = item.variants.map(
                (variant) => variant?.harga_normal
              );

              const promoFound = findOnePromo(item, promo);

              const total_stok = stok.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);

              const emptyStock = total_stok === 0 ? true : false;

              const harga_str = arrayToCurrencyString(harga);
              const promoF = promoFound ? true : false;

              const harga_str_promo =
                promoFound &&
                arrayToCurrencyStringPromo(
                  harga_normal,
                  promoFound,
                  level?.level,
                  global_komisi
                );

              const textBadgePromo = (() => {
                let text = "";

                if (promoFound) {
                  if (promoFound?.tipe === "%") {
                    text = `${promoFound?.nilai}%`;
                  } else {
                    text = `${promoFound?.nilai / 1000}K`;
                  }
                }

                return text;
              })();

              return (
                <div className={classes.wrapper} key={index}>
                  <Card className={classes.card}>
                    <CardActionArea
                      onClick={toDetailProduct(item.id, item.nama)}
                    >
                      {promo && !emptyStock ? (
                        <div className={classes.label}>
                          <Typography className={classes.txtLabel}>
                            {textBadgePromo}
                          </Typography>
                          <Typography className={classes.txtLabel}>
                            OFF
                          </Typography>
                        </div>
                      ) : null}
                      {emptyStock ? (
                        <div className={classes.empty}>
                          <span className={classes.txtEmpty}>Stok Habis</span>
                        </div>
                      ) : null}
                      {item.thumbnail?.length > 0 ? (
                        <div
                          loading="lazy"
                          // src={item.thumbnail[0]}
                          alt={item.id}
                          className={classes.img}
                          style={{
                            backgroundImage: `url(${item.thumbnail[0]})`,
                          }}
                        />
                      ) : (
                        <div
                          loading="lazy"
                          // src={item.thumbnail}
                          alt={item.id}
                          className={classes.img}
                          style={{
                            backgroundImage: `url(${item.thumbnail})`,
                          }}
                        />
                      )}
                    </CardActionArea>
                    {/* <div className={classes.contentWrapper}> */}
                    <div className={classes.content}>
                      <div className={classes.labelFlash}>{mode}</div>
                      <Typography className={classes.title} component="p">
                        {item?.nama}
                      </Typography>
                      {/* <div className={classes.labelKomisi}>
                            {global_komisi?.[level?.level] * 100}% KOMISI
                          </div> */}
                      <Typography className={classes.price} component="p">
                        {promoFound ? harga_str_promo : harga_str}
                      </Typography>
                    </div>
                    <div className={classes.btnWrap}>
                      {emptyStock ? (
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={classes.btn}
                          style={{
                            backgroundColor: "rgb(255, 107, 107)",
                          }}
                        >
                          Stok Habis
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.btn}
                          onClick={toDetailProduct(item.id, item.nama)}
                        >
                          Beli
                        </Button>
                      )}
                    </div>
                    {/* </div> */}
                  </Card>
                </div>
              );
            })}
        </Grid>
        {/* </TabPanel>
        </div> */}
      </DialogContent>
    </Dialog>
  );
}

export default DialogFlashsale;
