import React, { Suspense } from "react";

// React-Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Progress } from "../components";

// Main Route
import MainRoute from "./MainRoute";

// Component's
import {
  NotFound,
  LayoutA,
  LayoutB,
  LayoutC,
  Transaksi,
  Finish,
  LayoutLanding
} from "../pages";
import SecondRoute from "./SecondRoute";
import LandingRoute from "./LandingRoute"

export default function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<Progress />}>
        <Switch>
          <MainRoute path="/" exact component={LayoutA} />
          <MainRoute path="/kategori" component={LayoutA} />
          <MainRoute path="/akun" component={LayoutA} />
          <MainRoute path="/browse" exact component={LayoutB} />
          <MainRoute path="/katalog/:kategori/:id" exact component={LayoutB} />
          <MainRoute path="/daftar-alamat" component={LayoutB} />
          <MainRoute path="/rincian-alamat/:id/:length" component={LayoutB} />
          <MainRoute path="/produk/:name/:id" component={LayoutB} />
          {/* <MainRoute path="/payment" component={LayoutB} /> */}
          <MainRoute path="/cart" component={LayoutB} />
          <MainRoute path="/order-tracking" component={LayoutB} />
          <MainRoute path="/tracking/:orderId" component={LayoutB} />
          <MainRoute path="/payment/:token" component={LayoutB} />
          <MainRoute path="/moota/:token" component={LayoutB} />
          <MainRoute path="/transaksi" component={Transaksi} />
          <MainRoute path="/detail-order" component={LayoutB} />
          <MainRoute path="/finish/:id" component={Finish} />
          <LandingRoute path="/landingpage-form" component={LayoutLanding} />
          <SecondRoute path="/:brand" component={LayoutC} />
          <SecondRoute path="/brand/:name/:id" component={LayoutC} />
          <Route path="/404-rna" component={NotFound} />
          <Route render={() => <NotFound />} />
        </Switch>
      </Suspense>
    </Router>
  );
}
