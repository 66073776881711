import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";


function SkeletonProduct() {
    
  const classes = useStyles();

  return (
    <div className={classes.gridRoot}>
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          <Skeleton variant="rect" width="100%" height={200} />
          <div className={classes.intoSke}>
            <Typography component="div" variant="body1">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="caption">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
          </div>
        </Card>
      </div>
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          <Skeleton variant="rect" width="100%" height={200} />
          <div className={classes.intoSke}>
            <Typography component="div" variant="body1">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="caption">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
          </div>
        </Card>
      </div>
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          <Skeleton variant="rect" width="100%" height={200} />
          <div className={classes.intoSke}>
            <Typography component="div" variant="body1">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="caption">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
          </div>
        </Card>
      </div>
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          <Skeleton variant="rect" width="100%" height={200} />
          <div className={classes.intoSke}>
            <Typography component="div" variant="body1">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="caption">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SkeletonProduct;
