import React, { useContext, useState } from "react";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

// Firebase Config
import { FirebaseConfig } from "../../../config";

// Firebase Hooks
import { useDocumentData } from "react-firebase-hooks/firestore";

// Progress
import { AppLoading } from "../../atoms";

// Initialize
firebase.initializeApp(FirebaseConfig);

// Constanta
const region =
  process.env.REACT_APP_FIREBASE_PROD === "YES"
    ? "asia-southeast2"
    : "us-central1";
export const firestore = firebase.firestore();
export const functions = firebase.app().functions(region);
export const FieldValue = firebase.firestore.FieldValue;
export const analytics = firebase.analytics();
export const GeoPoint = firebase.firestore.GeoPoint;

// Context
const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export default function FirebaseProvider(props) {
  const [subdomain] = useState(window.location.hostname.split(".")[0]);

  const subdomainDoc =
    subdomain.length <= 2 || subdomain[0] === "www"
      ? null
      : firestore.doc(`subdomains/${subdomain}`);

  const [subdomainDetail, loadingSubdomian] = useDocumentData(subdomainDoc);

  if (loadingSubdomian) {
    return <AppLoading />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        firestore,
        functions,
        subdomainDetail,
        subdomain,
        analytics,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
}
