import React from "react";

// material-ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
// Styles
import useStyles from "./styles";

export default function Progress() {
  const classes = useStyles();

  return (
    <Container
      maxWidth="md"
      classes={{ root: classes.container }}
      className={classes.mainContainer}
    >
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </Container>
  );
}
