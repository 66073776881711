import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  hero: {
    filter: "brightness(55%)",
    objectFit: "cover",
    width: "100%",
    height: 300,
  },
  productContent: {
    display: "flex",
    flexWrap: "wrap",
    margin: "8px 5px 0",
  },
  filter: {
    height: 46,
    position: "relative",
    maxWidth: "100%",
  },
  productFilter: {
    maxWidth: "100%",
    width: 960,
    left: 0,
    right: 0,
    height: "inherit",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    boxShadow: "0 1px 0 0 rgba(0,0,0,.1)",
    backgroundColor: "#FFF",
    paddingLeft: 12,
    zIndex: 1000,
  },
  model: {
    flex: 1,
    padding: "0 12px",
  },
  label: {
    fontSize: 12,
    color: "#888",
    display: "block",
  },
  desc: {
    fontSize: 14,
    fontWeight: 700,
  },
  filterBtn: {
    padding: "0 16px",
    borderLeft: "1px solid #e5e5e5",
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  loading: {
    margin: "0 auto",
  },
}));

export default useStyles;
