import React, { useEffect, useState } from "react";

// Material UI
import { Card, Typography, Button, CardActionArea } from "@material-ui/core";

// Styles
import useStyles from "./styles";

function ProductCard({
  data,
  image,
  title,
  price = 0,
  onClick,
  total_stok,
  emptyStok,
  promo,
  promoFound,
  promoPrice,
}) {
  const classes = useStyles();
  const textBadgePromo = (() => {
    let text = "";

    if (promoFound) {
      if (promoFound?.tipe === "%") {
        text = `${promoFound?.nilai}%`;
      } else {
        text = `${promoFound?.nilai / 1000}K`;
      }
    }

    return text;
  })();
  const [newArrival, setNewArrival] = useState(false);
  // console.log(newArrival);
  useEffect(() => {
    const time = new Date();
    const timeSeconds = Math.round(time.getTime() / 1000);
    const timeNewArrival = data?.new_arrival?.seconds;
    const expiredTime = timeNewArrival - timeSeconds;
    if (expiredTime > 0) {
      setNewArrival(true);
    } else {
      setNewArrival(false);
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <CardActionArea onClick={onClick}>
          {promo && !emptyStok ? (
            <div className={classes.label}>
              <Typography className={classes.txtLabel}>
                {textBadgePromo}
              </Typography>
              <Typography className={classes.txtLabel}>OFF</Typography>
            </div>
          ) : null}

          {emptyStok ? (
            <div className={classes.empty}>
              <span className={classes.txtEmpty}>Stok Habis</span>
            </div>
          ) : null}
          {image?.length > 0 ? (
            <div
              loading="lazy"
              // src={image[0]}
              alt="foto"
              className={classes.img}
              style={{
                backgroundImage: `url(${image[0]})`,
              }}
            />
          ) : (
            <div
              loading="lazy"
              // src={image}
              alt="foto"
              className={classes.img}
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          )}
        </CardActionArea>
        <div className={classes.content}>
          <div style={{ display: "flex" }}>
            {promoFound?.flash_sale || promoFound?.special_offer ? (
              <div className={classes.labelFlash}>
                {promoFound?.flash_sale ? "Flashsale" : "Special Offer"}
              </div>
            ) : null}
            {data?.properties?.type === "preorder" ? (
              <div className={classes.labelPre}>Pre Order</div>
            ) : (
              newArrival && <div className={classes.labelNew}>Baru</div>
            )}
          </div>
          <Typography noWrap className={classes.title} component="p">
            {title}
          </Typography>
          <div>
            {data?.properties?.type === "preorder" ? (
              ""
            ) : (
              <>
                <Typography className={classes.txtStockTersedia} component="p">
                  Stok tersedia
                </Typography>
                <Typography className={classes.txtStock} component="p">
                  {total_stok} Pcs
                </Typography>
              </>
            )}
          </div>
          <Typography noWrap className={classes.price} component="p">
            {promoFound ? promoPrice : price}
          </Typography>
        </div>
        <div className={classes.btnWrap}>
          {emptyStok ? (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="small"
              className={classes.btn}
              onClick={onClick}
              style={{
                backgroundColor: "rgb(255, 107, 107)",
              }}
            >
              Stok Habis
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              className={classes.btn}
              onClick={onClick}
            >
              Lihat Produk
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}

export default ProductCard;
