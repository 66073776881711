import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { firestore, Gap, Divider, SkeletonProduct, ProductCard, functions } from "../../components";
import { useHistory } from "react-router-dom";
import { arrayToCurrencyString, reduceVariants } from "../../utils";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import Carousel from "nuka-carousel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import YouTube from "react-youtube";
import ScrollContainer from "react-indiana-drag-scroll";
import useStyles from "./styles";

function DetailProduk() {
  const classes = useStyles();
  const { name, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory()
  const [product, setProduct] = useState({});
  const [variantIdSelected, setVariantIdSelected] = useState();
  const [variantSelected, setVariantSelected] = useState({});
  const [variant1, setVariant1] = useState();
  const [variant2, setVariant2] = useState();
  const [variant1Deps, setVariant1Deps] = useState();
  const [variant2Deps, setVariant2Deps] = useState();
  const [loadProducts, setLoadProducts] = useState(false);
  const [productsBrand, setProductsBrand] = useState([]);
  const [productsKat, setProductsKat] = useState([]);

  const productRef = firestore.collection("products").doc(id);
  const variantsRef = productRef.collection("variants");
  const [productDB, loadingProduct] = useDocumentData(productRef, {
    idField: "id",
  });
  const [variants, loadingVariants] = useCollectionData(variantsRef, {
    idField: "id",
  });


  useEffect(() => {
    if (!loadingProduct && !loadingVariants) {
      if (productDB?.id) {
        setProduct({
          id: productDB?.id,
          ...productDB,
          variants: variants,
        });
      } else {
        enqueueSnackbar("Mohon Maaf Produk Tidak Ditemukan", {
          variant: "warning",
        });
        return history.goBack();
      }
    }
  }, [loadingProduct, loadingVariants, productDB, variants]);

  useEffect(() => {
    if (product?.brand?.nama && product?.kategori3?.nama && product?.id) {
      async function getRelatedItems() {
        const arrVal = [
          { key: 1, label: "brand_nama", value: product?.brand?.nama },
          { key: 2, label: "kategori3_nama", value: product?.kategori3?.nama },
        ];
        try {
          setLoadProducts(true);
          await Promise.all(
            arrVal.map(async (val) => {
              const getRelatedProducts =
                functions.httpsCallable("getRelatedProducts");
              const result = await getRelatedProducts({
                find_by_attr: val?.label,
                find_by_val: val?.value,
              });
              if (result.data.related_products?.length > 0) {
                let arr = [];
                await Promise.all(
                  result.data.related_products?.map(async (item) => {
                    const pro = await firestore.collection("products").doc(item.id_produk).get();
                    // console.log("b", await pro.ref.collection("variants").get());
                    if (!pro.empty && item.id_produk !== product.id) {
                      const variants = await pro.ref
                        .collection("variants")
                        .get();
                      const prodVariant = {
                        id: pro?.id,
                        id_produk: pro?.id,
                        ...pro?.data(),
                        variants: variants.docs.map((variant) => {
                          return {
                            id: variant.id,
                            ...variant.data(),
                          };
                        }),
                      };
                      // console.log("var",prodVariant);
                      arr.push(prodVariant);
                    }
                    // console.log("prod", arr);
                    if (val?.key === 1) {
                      setProductsBrand([...arr]);
                    } else {
                      setProductsKat([...arr]);
                    }
                    // setProducts(arr);
                  })
                );
              } else {
                const query = await firestore.collection("products")
                  .where(val?.label?.replace("_", "."), "==", val?.value)
                  .limit(5)
                  .get();
                // console.log("a",query);
                if (!query.empty) {
                  const newProducts = await Promise.all(
                    query.docs.map(async (product) => {
                      const variants = await product.ref
                        .collection("variants")
                        .get();
                      return {
                        id: product?.id,
                        id_produk: product?.id,
                        ...product?.data(),
                        variants: variants.docs.map((variant) => {
                          return {
                            id: variant.id,
                            ...variant.data(),
                          };
                        }),
                      };
                    })
                  );
                  const filteredItems = newProducts.filter(
                    (item) => item.id_produk !== product?.id
                  );
                  if (val?.key === 1) {
                    setProductsBrand([...filteredItems]);
                  } else {
                    setProductsKat([...filteredItems]);
                  }
                }
              }
            })
          );
          setLoadProducts(false);
        } catch (e) {
          setLoadProducts(false);
          console.log("error", e.message);
        }
      }
      getRelatedItems();
    }
  }, [product?.brand?.nama, product?.id, product?.kategori3?.nama]);

  useEffect(() => {
    if (variantIdSelected) {
      const findVariant = product?.variants?.find(
        (variant) => variant.id === variantIdSelected
      );

      if (findVariant) {
        setVariantSelected(findVariant);
      } else {
        setVariantSelected({});
      }
    } else {
      setVariantSelected({});
    }
  }, [variantIdSelected, product]);

  if (!product.variants) {
    return (
      <div>
        <CircularProgress style={{ marginTop: "47.5%", marginLeft: "47.5%" }} />
      </div>
    );
  }

  const handleChangeVariant1 = (value, deps) => (e) => {
    if (variant1 === value) {
      setVariant1();
      setVariant2Deps();
      setVariant2();
      setVariantIdSelected();
    } else {
      setVariant1(value);
      setVariant2();
      setVariantIdSelected();
      setVariant2Deps(deps);
    }
  };

  const handleChangeVariant2 = (value, deps) => (e) => {
    if (variant2 === value) {
      setVariant2();
      setVariant1();
      setVariant1Deps();
      setVariantIdSelected();
    } else {
      setVariant2(value);
      setVariant1();

      setVariantIdSelected();
      setVariant1Deps(deps);
    }
  };

  const chooseVariantIDFromVariant1 = (value, id) => (e) => {
    if (variant1 === value) {
      setVariant1();
      setVariantIdSelected();
    } else {
      setVariant1(value);
      setVariantIdSelected(id);
    }
  };

  const chooseVariantIDFromVariant2 = (value, id) => (e) => {
    if (variant2 === value) {
      setVariant2();
      setVariantIdSelected();
    } else {
      setVariant2(value);
      setVariantIdSelected(id);
    }
  };

  const allVariantThumbnails =
    product?.variants?.reduce((prev, curr) => {
      const currentThumbs =
        curr.thumbnail?.map((thumb, index) => ({
          key: curr.id + "_" + index,
          id: curr.id,
          thumbnail: thumb,
          type: "variant",
        })) ?? [];
      return [...prev, ...currentThumbs];
    }, []) ?? [];

  const productThumbnails =
    product?.thumbnail?.reduce((prev, curr, index) => {
      return [
        ...prev,
        {
          key: product?.id + "_" + index,
          id: product?.id,
          thumbnail: curr,
          type: "product",
        },
      ];
    }, []) ?? [];

  const notUniqthumbnails = variantIdSelected
    ? variantSelected?.thumbnail?.length > 0
      ? variantSelected?.thumbnail?.map((thumb, index) => {
          return {
            key: variantIdSelected + "_" + index,
            id: variantIdSelected,
            thumbnail: thumb,
            type: "variant",
          };
        }) ?? []
      : [
          {
            key: variantIdSelected + "_" + 1,
            id: variantIdSelected,
            thumbnail:
              "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg",
            type: "variant",
          },
        ]
    : [...productThumbnails, ...allVariantThumbnails];

  const thumbnails = uniqBy(notUniqthumbnails, (value) => value.thumbnail);

  const formattedVariants = reduceVariants(product?.variants ?? []);

  const all_harga_normal = variantIdSelected
  ? variantSelected?.harga_normal
  : product?.variants?.map((variant) => variant[`harga_normal`]) ?? [];

const all_harga_str = arrayToCurrencyString(all_harga_normal);

  const stok_available = variantIdSelected
    ? variantSelected.stok_available
    : product?.variants?.reduce((prev, curr) => {
        const stok = curr.stok_available ? curr.stok_available : 0;
        return prev + stok;
      }, 0) ?? 0;

  const sku = variantIdSelected ? variantSelected.sku : product?.brand?.nama;
  
  const opts = {
    height: "240",
    width: "320",
    playerVars: {
      rel: 0,
      autoplay: 0,
    },
  };
  const onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <div>
      <AppBar style={{ position: "relative" }} className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={classes.titleDialog}>
            {product?.nama}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          renderCenterLeftControls={({ previousSlide, currentSlide }) =>
            currentSlide !== 0 ? (
              <IconButton size="small" onClick={previousSlide}>
                <NavigateBefore fontSize="large" />
              </IconButton>
            ) : null
          }
          renderCenterRightControls={({
            nextSlide,
            slideCount,
            currentSlide,
          }) =>
            currentSlide !== slideCount - 1 ? (
              <IconButton size="small" onClick={nextSlide}>
                <NavigateNext fontSize="large" />
              </IconButton>
            ) : null
          }
        >
          {thumbnails.map((item, index) => {
            return (
              <img
                loading="lazy"
                key={index}
                src={item.thumbnail}
                alt={item.key}
                style={{
                  objectFit: "contain",
                  width: 360,
                  height: 360,
                  margin: "0 auto",
                }}
              />
            );
          })}
        </Carousel>
        <div className={classes.head}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={classes.label}>{sku}</div>
            <Gap width={6} />
            {stok_available <= 0 && (
              <div className={classes.label}>Stok Habis</div>
            )}
          </div>
          <Typography>{product?.nama}</Typography>
        </div>
        <Divider />
        <div className={classes.priceWrapper}>
          <div className={classes.sectionPrice}>
            <Typography component="small" className={classes.titlePrice}>
              Harga
            </Typography>
            <br />
            <div style={{ display: "flex" }}>
              <div className={classes.descPrice}>
                <b>{all_harga_str}</b>
              </div>
            </div>
          </div>
          <div className={classes.sectionPrice}>
            <Typography component="small" className={classes.titlePrice}>
              Stok Tersedia
            </Typography>
            <br />
            {(variant1 && variant2) || (!variant1 && !variant2) ? (
              <div className={classes.descPrice}>
                <b>{stok_available > 0 ? stok_available : 0}</b>
              </div>
            ) : null}
          </div>
        </div>
        <Divider />
        <div className={classes.variantContent}>
          <Typography component="h3" className={classes.h3Title}>
            Pilih {product?.variant1_label}
          </Typography>
          {variant1Deps ? (
            <div className={classes.row}>
              {variant1Deps.map((item) => {
                const isSelected = variant1 === item.variant1_value;
                const stok = item.stok_available ? item.stok_available : 0;
                if (stok <= 0) {
                  return null;
                } else {
                  return (
                    <Button
                      key={item.variant1_value}
                      onClick={chooseVariantIDFromVariant1(
                        item.variant1_value,
                        item.id
                      )}
                      variant={isSelected ? "contained" : "outlined"}
                      classes={{ root: classes.btnt }}
                      color="primary"
                      className={classes.btnSelect}
                    >
                      {item.variant1_value?.toUpperCase?.() ??
                        item.variant1_value}
                    </Button>
                  );
                }
              })}
            </div>
          ) : (
            <div className={classes.row}>
              {Object.keys(formattedVariants?.variant1_values ?? {}).map(
                (val) => {
                  const deps = formattedVariants?.variant1_values[val];
                  const isSelected = variant1 === val;
                  let stok = deps.reduce((prev, curr) => {
                    const stok = curr.stok_available ? curr.stok_available : 0;
                    return prev + stok;
                  }, 0);
                  if (stok <= 0) {
                    return null;
                  } else {
                    return (
                      <Button
                        key={val}
                        onClick={handleChangeVariant1(val, deps)}
                        variant={isSelected ? "contained" : "outlined"}
                        classes={{ root: classes.btnt }}
                        color="primary"
                        className={classes.btnSelect}
                      >
                        {val?.toUpperCase?.() ?? val}
                      </Button>
                    );
                  }
                }
              )}
            </div>
          )}
          <Gap height={16} />
          <Typography component="h3" className={classes.h3Title}>
            Pilih {product?.variant2_label}
          </Typography>
          {variant2Deps ? (
            <div className={classes.row}>
              {variant2Deps.map((item) => {
                const isSelected = variant2 === item.variant2_value;
                const stok = item.stok_available ? item.stok_available : 0;
                if (stok <= 0) {
                  return null;
                } else {
                  return (
                    <Button
                      key={item.variant2_value}
                      onClick={chooseVariantIDFromVariant2(
                        item.variant2_value,
                        item.id
                      )}
                      variant={isSelected ? "contained" : "outlined"}
                      classes={{ root: classes.btnt }}
                      color="primary"
                      className={classes.btnSelect}
                    >
                      {item.variant2_value?.toUpperCase?.() ??
                        item.variant2_value}
                    </Button>
                  );
                }
              })}
            </div>
          ) : (
            <div className={classes.row}>
              {Object.keys(formattedVariants?.variant2_values ?? {}).map(
                (val) => {
                  const deps = formattedVariants?.variant2_values[val];

                  const isSelected = variant2 === val;
                  let stok = deps.reduce((prev, curr) => {
                    const stok = curr.stok_available ? curr.stok_available : 0;
                    return prev + stok;
                  }, 0);
                  if (stok <= 0) {
                    return null;
                  } else {
                    return (
                      <Button
                        key={val}
                        onClick={handleChangeVariant2(val, deps)}
                        variant={isSelected ? "contained" : "outlined"}
                        classes={{ root: classes.btnt }}
                        color="primary"
                        className={classes.btnSelect}
                      >
                        {val?.toUpperCase?.() ?? val}
                      </Button>
                    );
                  }
                }
              )}
            </div>
          )}
        </div>
        <Divider />
        <>
          <div className={classes.descHead}>
            <b>Deskripsi Produk</b>
          </div>
          {product?.video_id ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 10,
              }}
            >
              <YouTube
                videoId={product?.video_id}
                opts={opts}
                onReady={onReady}
              />
            </div>
          ) : null}
          <div className={classes.descBody}>{product?.deskripsi_lengkap}</div>
        </>
        <Divider />
        <div>
          <div className={classes.descHead}>
            <b>Produk Lain Dari Brand {product?.brand?.nama}</b>
          </div>
          {loadProducts ? (
            <SkeletonProduct />
          ) : (
            <>
              {productsBrand?.length <= 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "100%",
                    marginBottom: "25px",
                  }}
                >
                  <p>
                    <b>Produk Tidak Ditemukan</b>
                  </p>
                </div>
              ) : (
                <ScrollContainer className={classes.wrapScroll}>
                  {productsBrand?.map((item, index) => {
                    const harga = item.variants.map(
                      (variant) => variant.harga_normal
                    );

                    const stok = item.variants.map(
                      (variant) => variant.stok_available
                    );

                    const total_stok = stok.reduce((val, nilaiSekarang) => {
                      return val + nilaiSekarang;
                    }, 0);

                    const harga_str = arrayToCurrencyString(harga);

                    return (
                      <ProductCard
                        data={item}
                        total_stok={total_stok}
                        key={index}
                        image={item.thumbnail}
                        title={item.nama}
                        price={harga_str}
                        // promoPrice={harga_str_promo}
                        // promoFound={promoFound}
                        // promo={promoFound ? true : false}
                        emptyStok={total_stok === 0 ? true : false}
                      />
                    );
                  })}
                </ScrollContainer>
              )}
            </>
          )}
        </div>
        <Divider />
        <div>
          <div className={classes.descHead}>
            <b>Produk Lain Dari Kategori {product?.kategori3?.nama}</b>
          </div>
          {loadProducts ? (
            <SkeletonProduct />
          ) : (
            <>
              {productsKat?.length <= 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "100%",
                    marginBottom: "25px",
                  }}
                >
                  <p>
                    <b>Produk Tidak Ditemukan</b>
                  </p>
                </div>
              ) : (
                <ScrollContainer className={classes.wrapScroll}>
                  {productsKat?.map((item, index) => {
                    const harga = item.variants.map(
                      (variant) => variant.harga_normal
                    );

                    const stok = item.variants.map(
                      (variant) => variant.stok_available
                    );

                    const total_stok = stok.reduce((val, nilaiSekarang) => {
                      return val + nilaiSekarang;
                    }, 0);

                    const harga_str = arrayToCurrencyString(harga);

                    return (
                      <ProductCard
                        data={item}
                        total_stok={total_stok}
                        key={index}
                        image={item.thumbnail}
                        title={item.nama}
                        price={harga_str}
                        // promoPrice={harga_str_promo}
                        // promoFound={promoFound}
                        // promo={promoFound ? true : false}
                        // onClick={() => {
                        //   onClose();
                        //   setDialog({ open: true, product: item });
                        // }}
                        emptyStok={total_stok === 0 ? true : false}
                      />
                    );
                  })}
                </ScrollContainer>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailProduk;
