import React, { useContext, useEffect, useState } from "react";

// Component's
import { firestore } from "../FirebaseProvider";
import { SkeletonLoading } from "../../atoms";
//firebase
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
//

// Context
const ProductContext = React.createContext();

export function useProduct() {
  return useContext(ProductContext);
}

export default function ProductProvider({ children }) {

  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  const [lastVisible, setLastVisibles] = useState();
  const [loadingMore, setLoadingMore] = useState(true)


  const [typesenseConfig, loadingTypesenseConfig] = useDocumentDataOnce(
    firestore.doc("integrations/typesense"),
    { idField: "id" }
  );
  const [configuration, loadingConfiguration] = useDocumentData(
    firestore.doc("settings/configuration"),
    { idField: "id" }
  );

  useEffect(() => {
    const productsRef = firestore.collection("products");
    async function getProducts() {
      setLoading(true);
      setProducts([]);
      // limit 2 products in
      const query = await productsRef
        .where("sembunyikan_produk", "==", false)
        .orderBy("created_at", "desc")
        .limit(13)
        .get();

      if (!query.empty) {
        const newProducts = await Promise.all(
          query.docs.map(async (product) => {
            const variants = await product.ref
              .collection("variants")
              .where(`harga_normal`, ">", 0)
              .get();
            return {
              id: product.id,
              ...product.data(),
              variants: variants.docs.map((variant) => {
                return {
                  id: variant.id,
                  ...variant.data(),
                };
              }),
            };
          })
        );
        const filteredProducts = newProducts.filter(
          (product) => product.variants.length > 0
        );
        setProducts((products) => [...products, ...filteredProducts]);
        setLastVisibles(query.docs[query.docs.length - 1]);
      }

      setLoading(false);
    }

    getProducts();
  }, []);

  const loadMoreProducts = async () => {
    if (lastVisible && loadingMore) {
      setLoadingMore(true);
      try {
        const productsRef = firestore.collection("products");
        const query = await productsRef
          .where("sembunyikan_produk", "==", false)
          .orderBy("created_at", "desc")
          .startAfter(lastVisible)
          .limit(12)
          .get();
        if (!query.empty) {
          const newProducts = await Promise.all(
            query.docs.map(async (product) => {
              const variants = await product.ref
                .collection("variants")
                .where(`harga_normal`, ">", 0)
                .get();
              return {
                id: product.id,
                ...product.data(),
                variants: variants.docs.map((variant) => {
                  return {
                    id: variant.id,
                    ...variant.data(),
                  };
                }),
              };
            })
          );

          const filteredProducts = newProducts.filter(
            (product) => product.variants.length > 0
          );
          setProducts((products) => [...products, ...filteredProducts]);
          
          setLastVisibles(query.docs[query.docs.length - 1]);
        } else {
          setLastVisibles(null);
          setLoadingMore(false);
        }
      } catch (e) {}
    }
  };
  if(loading){
    <SkeletonLoading />
  }

  return (
    <ProductContext.Provider
      value={{
        products,
        setLoadingMore,
        loadingMore,
        loading,
        loadMoreProducts,
        typesenseConfig,
        loadingTypesenseConfig,
        configuration,
        loadingConfiguration

      }}
    >
      {children}
    </ProductContext.Provider>
  );
}
