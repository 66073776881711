import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { Headerkatalog, Gap, useProduct } from "../../components";
import slugify from "slugify";
import useStyles from "./styles";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectHighlight,
  Configure,
} from "react-instantsearch-dom";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { indexName, searchClient } from "../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SearchBox({ currentRefinement, refine, handleClose }) {
  const history = useHistory()
  return (
    <>
    <Headerkatalog
    type="with-back"
      autoFocus
      placeholder="Cari Produk"
      value={currentRefinement}
      onChange={(e) => refine(e.target.value)}
      onClick={() => history.goBack()}
    />
    </>
  );
}

const ConnectSearchBox = connectSearchBox(SearchBox);

function Highlight({ hit, highlight, configuration }) {
  const history = useHistory()
  const { search_module } = configuration;

  const checkSearchEngineWithAlgolia =
    search_module === "algolia" ? true : false;

  const {
    highlightsProductName,
    highlightsDeskripsiSingkat,
    isProdukId,
    isBrand,
  } = useMemo(() => {
    let myPropertyHightlight = {
      highlightProperty: "_highlightResult",
      hit,
    };

    let myObj = {
      attributeProductName: checkSearchEngineWithAlgolia ? "nama" : "name",
      attributeProductDesc: checkSearchEngineWithAlgolia
        ? "deskripsi_singkat"
        : "description",
      isProdukId: checkSearchEngineWithAlgolia ? hit?.produkId : hit?.id,
      isBrand: checkSearchEngineWithAlgolia ? hit?.brand?.nama : hit?.brand,
    };
    myObj = {
      isProdukId: myObj?.isProdukId,
      isBrand: myObj?.isBrand,
      highlightsProductName: highlight({
        ...myPropertyHightlight,
        attribute: myObj?.attributeProductName,
      }),
      highlightsDeskripsiSingkat: highlight({
        ...myPropertyHightlight,
        attribute: myObj?.attributeProductDesc,
      }),
    };

    return { ...myObj };
  }, [search_module, hit, highlight]);

  const toDetailProduct = (id, title) => {
    history.push(`/produk/${slugify(title.toLowerCase())}/${id}`);
  };

  return (
    <>
      <ListItem
        alignItems="flex-start"
        button
        onClick={() => toDetailProduct(isProdukId, checkSearchEngineWithAlgolia ? hit.nama : hit.name)}
      >
        <ListItemAvatar>
          <Avatar
            alt={checkSearchEngineWithAlgolia ? hit.nama : hit.name}
            src={
              hit.thumbnail ? hit.thumbnail : "https://via.placeholder.com/40"
            }
            variant="rounded"
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {highlightsProductName.map(({ value, isHighlighted }, index) => {
                const style = {
                  fontWeight: isHighlighted ? "bold" : "normal",
                };

                return (
                  <span key={index} style={style}>
                    {value}
                  </span>
                );
              })}
              <span> - </span>
              <span>{isBrand ? isBrand : ""}</span>
            </>
          }
          secondary={
            <>
              {highlightsDeskripsiSingkat.map(
                ({ value, isHighlighted }, index) => {
                  const style = {
                    fontWeight: isHighlighted ? "bold" : "normal",
                  };

                  return (
                    <span key={index} style={style}>
                      {value}
                    </span>
                  );
                }
              )}
            </>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </>
  );
}

const ConnectedHighlight = connectHighlight(Highlight);

function Hits({ hits, configuration }) {
  return (
    <List style={{ width: "100%" }}>
      {hits.length > 0 ? (
        hits.map((item, index) => {
          return (
            <ConnectedHighlight
              key={index}
              attribute="nama"
              hit={item}
              configuration={configuration}
            />
          );
        })
      ) : (
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginTop: "2.5%",
          }}
        >
          Produk Tidak Ditemukan
        </Typography>
      )}
    </List>
  );
}

const ConnectedHits = connectHits(Hits);

function Browse() {
  const {typesenseConfig, configuration} = useProduct()
  const classes = useStyles();

  const { configuration: isConfiguration } = typesenseConfig;
  let TYPESENSE_SERVE_CONFIG = {
    ...isConfiguration,
    numRetries: 4,
    connectionTimeoutSeconds: 1,
  };

  const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
    server: TYPESENSE_SERVE_CONFIG,
    additionalSearchParameters: {
      query_by:
        "name, description, brand, variants_1, variants_2, categories, keywords, type",
      num_typos: 1,
      typo_tokens_threshold: 1,
    },
  });

  const { search_module } = configuration;

  const [searchState, setSearchState] = useState({});

  const { isClient, isIndexName } = useMemo(() => {
    const typesense_client = typesenseInstantsearchAdapter.searchClient;
    const typesense_index =
      process.env.REACT_APP_FIREBASE_PROD === "YES"
        ? "products"
        : "products_dev";
    const algolia_client = searchClient;
    const algolia_index = indexName;

    let myObj =
      search_module === "algolia"
        ? {
            isClient: algolia_client,
            isIndexName: algolia_index,
          }
        : {
            isClient: typesense_client,
            isIndexName: typesense_index,
          };

    return { ...myObj };
  }, [search_module]);

  return (
    <div className={classes.root}>
      <InstantSearch
        searchClient={isClient}
        searchState={searchState}
        onSearchStateChange={setSearchState}
        indexName={isIndexName}
      >
        <ConnectSearchBox />
        <div className={classes.productContent}>
          <ConnectedHits configuration={configuration} />
        </div>
      </InstantSearch>
    </div>
  );
}

export default Browse;
