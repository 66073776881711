import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 5,
    // marginTop: "25px",
    // marginBottom: "100px",
  },
  container: {
    // padding: "10px",
    // margin: "5px 0px",
    borderRadius: "0px",
    backgroundColor: "#5675D3",
  },
  wrapScroll: {
    display: "flex",
    overflowX: "auto",
    flexWrap: "nowrap",
  },
  wrapper: {
    // display:"inline-table",
    // flex: "0 0 auto",
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 5,
    marginTop: 10,
    "@media(min-width: 280px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    // "@media(min-width: 320px)": {
    //   width: "calc(100%/2)",
    //   height: "100%",
    // },
    // "@media(min-width: 360px)": {
    //   width: "calc(100%/2)",
    //   height: "100%",
    // },
    "@media(min-width: 450px)": {
      width: "230px",
      height: "100%",
    },
    "@media(min-width: 800px)": {
      width: "calc(100%/4)",
      height: "100%",
    },
    // "@media(min-width: 960px)": {
    //   width: "calc(100%/4)",
    //   height: "100%",
    // },
  },
  card: {
    flex: "0 0 auto",
    height: "100%",
    borderRadius: 5,
    boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
  },
  label: {
    position: "absolute",
    boxShadow: "2px 2px darkslategrey",
    right: "5px",
    backgroundColor: "#e82868",
    lineHeight: "20px",
    color: "#fff",
    display: "inline-block",
    padding: "5px 5px",
    borderRadius: "0px 0px 20px 20px",
    marginBottom: 4,
    textAlign: "center",
  },
  img: {
    // width: "200px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 270,
    },
  },
  img_seceleton: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 300,
    },
  },
  contentWrapper: {
    height: 122,
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 15px",
    marginTop: 8,
  },
  title: {
    fontWeight: 700,
    fontSize: 14,
    // marginBottom: "15px",
    // textAlign: "center",
    color: "#333333",
    margin: "3px 0px",
    // maxWidth: "100%",
    width: "100%",
    overflow: "hidden",
    // marginTop:"5px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "@media(min-width: 280px)": {
      fontSize: 12,
    },
    "@media(min-width: 320px)": {
      fontSize: 12,
    },
    "@media(min-width: 360px)": {
      fontSize: 12,
    },
    "@media(min-width: 960px)": {
      fontSize: 14,
    },
  },
  price: {
    fontWeight: 700,
    fontSize: 12,
    color: "#eb6669",
    // textAlign: "center",
    // maxWidth: 139,
    whiteSpace: "nowrap",
    maxWidth: "100%",
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(max-width: 280px)": {
      // width: 100,
    },
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
    "@media(min-width: 960px)": {
      // maxWidth: 150,
    },
  },
  btnWrap: { margin: 8, marginBottom: 12 },
  btn: {
    color: "#ffff",
    fontWeight: 700,
    fontSize: 12,
    "@media(max-width: 280px)": {
      padding: "4px 4px",
    },
    textTransform: "capitalize",
  },
  empty: {
    backgroundColor: "hsla(0,0%,100%,.9)",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  txtEmpty: {
    fontSize: 18,
    fontWeight: 700,
    color: "rgba(0,0,0,.4)",
  },
  txtLabel: {
    fontSize: 12,
    color: "#FFF",
  },
  txtLabelharhag: {
    fontSize: 12,
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
  },
  labelKomisi: {
    backgroundColor: "#e82868",
    fontSize: 11,
    lineHeight: "20px",
    color: "#fff",
    display: "inline-block",
    padding: "0 4px",
    borderRadius: 4,
    marginBottom: 4,
    "@media(min-width: 280px)": {
      fontSize: 9,
      padding: "0 3px",
    },
    "@media(min-width: 320px)": {
      fontSize: 9,
      padding: "0 3px",
    },
    "@media(min-width: 360px)": {
      fontSize: 9,
      padding: "0 3px",
    },
    "@media(min-width: 960px)": {
      fontSize: 11,
      padding: "0 4px",
    },
  },
  large: {
    width: 100,
    height: 100,
  },
  liquidBold: {
    fontWeight: 700,
    fontSize: 12,
    color: "#00000",
    textAlign: "center",
    maxWidth: 139,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(max-width: 280px)": {
      width: 100,
    },
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
    "@media(min-width: 960px)": {
      maxWidth: 150,
    },
  },
  tab: {
    fontSize: "13px",
    "@media (max-width: 480px)": {
      fontSize: "11px",
    },
  },
  labelFlash: {
    backgroundColor: "#bde7ff6e",
    fontSize: 10,
    lineHeight: "20px",
    color: "#0088d6f7",
    display: "inline-block",
    padding: "2px 8px",
    fontWeight: "bold",
    borderRadius: 12,
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    // "@media(min-width: 280px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 320px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 360px)": {
    //   fontSize: 9,
    //   padding: "0 3px",
    // },
    // "@media(min-width: 960px)": {
    //   fontSize: 11,
    //   padding: "0 4px",
    // },
  },
}));
export default useStyles;
