import React from "react";
import GoogleMapReact from "google-map-react";
import { MapApiKey } from "../../../config";
import RoomIcon from "@material-ui/icons/Room";
import { red } from "@material-ui/core/colors";

function Maps({ center, currentPos, setCurrentPos }) {
  const handleClick = (e) => {
    setCurrentPos({
      lat: e.lat,
      lng: e.lng,
    });
  };

  return (
    <div style={{ width: "100%", height: 320 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: MapApiKey }}
        defaultCenter={center}
        defaultZoom={13}
        onClick={handleClick}
      >
        {currentPos && (
          <RoomIcon
            lat={currentPos?.lat}
            lng={currentPos?.lng}
            style={{
              color: red[500],
              position: "absolute",
            }}
          />
        )}
      </GoogleMapReact>
    </div>
  );
}

export default Maps;
