import React, { useContext, useState, useEffect, useMemo } from "react";

// React-Firebase-Hooks
import {
  useDocumentData,
  useCollectionData,
  useCollectionDataOnce,
  useDocumentDataOnce
} from "react-firebase-hooks/firestore";
import { arrayToTree } from "performant-array-to-tree";

// Component's
import { firestore, useFirebase } from "../FirebaseProvider";
import { SkeletonLoading } from "../../atoms";
import { getData, setData } from "../../../utils";

// Context
const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export default function DataProvider({ children }) {
  const [uid, setUID] = useState("");

  const [alamatId, setAlamatId] = useState("");

  const [cartBadge, setCartBadge] = useState([]);

  const [products, setProducts] = useState([]);

  const { subdomainDetail } = useFirebase();

  const tokoDoc =
    subdomainDetail !== undefined
      ? firestore.doc(`webstores/${subdomainDetail?.user_id}`)
      : null;

  const [userToko, loading] = useDocumentData(tokoDoc);
  // console.log(userToko);
  const cartDoc =
    uid !== "" && uid !== undefined
      ? firestore.collection(`carts`).doc(uid)
      : null;

  const [cart, loadingCart] = useDocumentData(cartDoc);

  const ordersRef =
    uid !== "" && uid !== undefined
      ? firestore
          .collection("orders")
          .where("user_id", "==", uid)
          .where("order_info.location", "==", "webstore")
      : null;

  const [orders, loadingOrders] = useCollectionData(ordersRef, {
    idField: "id",
  });

  const [alamat_pusat, loadingAlamatPusat] = useDocumentData(
    firestore.doc("settings/alamat_pusat"),
    { idField: "id" }
  );

  const [setting, loadingSetting] = useDocumentData(
    firestore.doc("settings/global"),
    { idField: "id" }
  );
  const categoriesRef = firestore.collection("categories");
  const [categories, loadingCategories] = useCollectionDataOnce(categoriesRef, {
    idField: "id",
  });

  const alamatRef =
    uid !== "" && uid !== undefined
      ? firestore
          .doc(`users/${uid}`)
          .collection("alamat")
          .where("type", "==", "webstore")
      : null;

  const [alamat, loadingAlamat] = useCollectionData(alamatRef, {
    idField: "id",
  });

  const [promo, loadingPromo] = useCollectionData(
    firestore.collection("promo").where("status", "==", "active"),
    { idField: "id" }
  );

  // get campaigns
  const campaignsRef = firestore
    .collection("campaigns")
    .where("show", "==", true);
  const [campaigns, loadingCampaigns] = useCollectionData(campaignsRef, {
    idField: "id",
  });
  // get campaigns infaq
  const campaignsInfaqRef = firestore
    .collection("campaigns")
    .where("status", "==", "active")
    .where("tipe", "==", "infaq");
  const [campaignsInfaq, loadingCampaignsInfaq] = useCollectionData(
    campaignsInfaqRef,
    {
      idField: "id",
    }
  );

  const [vouchers, loadingVouchers] = useCollectionData(
    firestore
      .collection("vouchers")
      .where("status", "==", "active")
      .where("location", "in", ["all", "webstore"]),
    { idField: "id" }
  );
  const [promoOngkir, loadingPromoOngkir] = useCollectionData(
    firestore.collection("promo_ongkir").where("status", "==", "active"),
    {
      idField: "id",
    }
  );

  const [jenisBrand, loadingJenisBrand, err] = useDocumentData(
    firestore.doc("utility/brand"),
    { idField: "id" }
  );

  // get bank imtegrasion
  const [integrasionPayments, loadingIntegrasionPayments] =
    useCollectionDataOnce(firestore.collection("integrations/moota/banks"), {
      idField: "id",
    });

  // get bank imtegrasion
  const [mootaConfig, loadingMootaConfig] = useDocumentData(
    firestore.doc("integrations/moota"),
    { idField: "id" }
  );

  // get typesense intergration

  const [typesenseConfig, loadingTypesenseConfig] = useDocumentDataOnce(
    firestore.doc("integrations/typesense"),
    { idField: "id" }
  );
  const [configuration, loadingConfiguration] = useDocumentData(
    firestore.doc("settings/configuration"),
    { idField: "id" }
  );

  const [preorderUtils, loadingPreorderUtils] = useDocumentData(
    firestore.doc("utility/preorder"),
    { idField: "id" }
  );

  useMemo(() => {
    if (cart && cart !== undefined && cart.subcarts !== undefined) {
      Object.keys(cart.subcarts).map((key) => {
        const data = cart.subcarts[key].products;

        const result = Object.keys(data).map((doc) => [doc]);

        return setCartBadge(result);
      });
    } else {
      setCartBadge([]);
    }
  }, [cart]);

  useEffect(() => {
    getUserData();
  });
  useEffect(() => {
    getUserAlamat();
  }, []);

  const getUserData = async () => {
    const res = await getData("uid");

    if (res === undefined) {
      const ref = firestore.collection("users").doc();
      setData("uid", ref.id);

      setUID(ref.id);
    }

    setUID(res);
  };

  const getUserAlamat = async () => {
    const res = await getData("alamat_id");
    setAlamatId(res);
  };

  // console.log("loading => ",loading )
  // console.log("loadingCart => ",loadingCart )
  // console.log("loadingOrders => ",loadingOrders )
  // console.log("loadingAlamatPusat => ",loadingAlamatPusat )
  // console.log("loadingAlamat => ",loadingAlamat )
  // console.log("loadingSetting => ",loadingSetting )
  // console.log("loadingPromo => ",loadingPromo )
  // console.log("loadingPromoOngkir => ",loadingPromoOngkir )

  if (
    loading ||
    loadingCart ||
    loadingOrders ||
    loadingAlamatPusat ||
    loadingAlamat ||
    loadingSetting ||
    loadingPromo ||
    loadingPromoOngkir ||
    loadingCategories ||
    loadingCampaigns ||
    loadingCampaignsInfaq ||
    loadingVouchers ||
    loadingJenisBrand ||
    loadingIntegrasionPayments ||
    loadingMootaConfig||
    loadingTypesenseConfig ||
    loadingConfiguration || 
    loadingPreorderUtils
  ) {
    return <SkeletonLoading />;
  }

  return (
    <DataContext.Provider
      value={{
        userToko,
        uid,
        mootaConfig,
        setUID,
        cartBadge,
        cart,
        jenisBrand,
        flatCategories: categories,
        categories: arrayToTree(categories, {
          idField: "id",
          parentId: "parent",
          childrenField: "sub",
          dataField: null,
        }),
        alamatId,
        setAlamatId,
        orders,
        alamat_pusat,
        alamat,
        setting,
        promo,
        promoOngkir,
        vouchers,
        campaigns,
        campaignsInfaq,
        integrasionPayments,
        typesenseConfig,
        configuration,
        products,
        setProducts,
        preorderUtils
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
