import React, { useState } from "react";

// React Router
import { Route } from "react-router-dom";
import { NotFound } from "../../pages";

export default function SecondRoute({ component: Component, ...restProps }) {
  const [subdomain] = useState(window.location.hostname.split("."));

  return (
    <Route
      {...restProps}
      render={(props) => {
        return subdomain.length <= 2 || subdomain[0] === "www" ? (
          <Component {...props} />
        ) : (
          <NotFound />
        );
      }}
    />
  );
}
