import React, { useState, useEffect } from "react";
import slugify from "slugify";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles/flashSaleStyle";
import ScrollContainer from "react-indiana-drag-scroll";
import SpesialOfferIcon from "../../assets/icon/spesial-offer.svg";
import FlashSaleIcon from "../../assets/icon/flashSaleIcon.svg";
import Button from "@material-ui/core/Button";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DialogFlashsale from "./dialogFlashsale";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Countdown from "react-countdown";

import {
  Card,
  Typography,
  CardActionArea,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import useCountDownTimer from "../../components/useCountDownTimer";
import { useHistory } from "react-router";
import { analytics, firestore, useData } from "../../components";
import {
  arrayToCurrencyString,
  arrayToCurrencyStringPromo,
  findOnePromo,
} from "../../utils";

function FlashSale() {
  const classes = useStyles();
  const history = useHistory();
  const [products, setProducts] = useState([]);
  // const [slicedProducts, setSlicedProducts] = useState([]);
  const [expiration_date, setExpire] = useState();
  const [keywords, setKeywords] = useState([]);
  const { seconds } = useCountDownTimer(expiration_date);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState();

  const theme = useTheme();

  const miniScreen = useMediaQuery(theme.breakpoints.down("960"));

  const Renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <>
        {days !== 0 ? (
          <div
            style={
              miniScreen
                ? {
                    backgroundColor: "rgb(86, 117, 211)",
                    borderRadius: "4px",
                    // display: "flex",
                    // marginLeft: "2px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                  }
                : {
                    backgroundColor: "rgb(86, 117, 211)",
                    borderRadius: "4px",
                    // display: "flex",
                    // marginLeft: "2px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }
            }
          >
            <Typography
              style={
                miniScreen
                  ? { fontWeight: "bold", fontSize: "10px" }
                  : { fontWeight: "bold", fontSize: "15px" }
              }
            >
              {days}
            </Typography>
            <Typography
              style={miniScreen ? { fontSize: "6px" } : { fontSize: "12px" }}
            >
              {" "}
              Hari
            </Typography>
          </div>
        ) : null}
        <div
          style={
            miniScreen
              ? {
                  backgroundColor: "rgb(86, 117, 211)",
                  borderRadius: "4px",
                  // display: "flex",
                  // marginLeft: "2px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                }
              : {
                  backgroundColor: "rgb(86, 117, 211)",
                  borderRadius: "4px",
                  // display: "flex",
                  // marginLeft: "2px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }
          }
        >
          <Typography
            style={
              miniScreen
                ? { fontWeight: "bold", fontSize: "10px" }
                : { fontWeight: "bold", fontSize: "15px" }
            }
          >
            {hours > 9 ? hours : `0${hours}`}
          </Typography>
          <Typography
            style={miniScreen ? { fontSize: "6px" } : { fontSize: "12px" }}
          >
            {" "}
            Jam
          </Typography>
        </div>
        <div
          style={
            miniScreen
              ? {
                  backgroundColor: "rgb(86, 117, 211)",
                  borderRadius: "4px",
                  // display: "flex",
                  // marginLeft: "2px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                }
              : {
                  backgroundColor: "rgb(86, 117, 211)",
                  borderRadius: "4px",
                  // display: "flex",
                  // marginLeft: "2px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }
          }
        >
          <Typography
            style={
              miniScreen
                ? { fontWeight: "bold", fontSize: "10px" }
                : { fontWeight: "bold", fontSize: "15px" }
            }
          >
            {minutes > 9 ? minutes : `0${minutes}`}
          </Typography>
          <Typography
            style={miniScreen ? { fontSize: "6px" } : { fontSize: "12px" }}
          >
            {" "}
            Menit
          </Typography>
        </div>
        <div
          style={
            miniScreen
              ? {
                  backgroundColor: "rgb(86, 117, 211)",
                  borderRadius: "4px",
                  // display: "flex",
                  // marginLeft: "2px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                }
              : {
                  backgroundColor: "rgb(86, 117, 211)",
                  borderRadius: "4px",
                  // display: "flex",
                  // marginLeft: "2px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }
          }
        >
          <Typography
            style={
              miniScreen
                ? { fontWeight: "bold", fontSize: "10px" }
                : { fontWeight: "bold", fontSize: "15px" }
            }
          >
            {seconds > 9 ? seconds : `0${seconds}`}
          </Typography>
          <Typography
            style={miniScreen ? { fontSize: "6px" } : { fontSize: "12px" }}
          >
            {" "}
            Detik
          </Typography>
        </div>
      </>
    );
  };

  const { level, promo, global_komisi, userToko } = useData();

  useEffect(() => {
    async function getProducts() {
      setLoading(true);
      try {
        const flashsaleRef = promo?.filter((doc) => doc?.flash_sale === true);
        const spesialOfferRef = promo?.filter(
          (doc) => doc?.special_offer === true
        );

        const dataPromoRef =
          flashsaleRef?.length > 0
            ? flashsaleRef
            : spesialOfferRef?.length > 0
            ? spesialOfferRef
            : [];

        if (dataPromoRef?.length) {
          let expire = dataPromoRef?.[0]?.end_at?.toMillis();
          setExpire(expire);
          setMode(
            dataPromoRef?.[0]?.flash_sale
              ? "Flashsale"
              : dataPromoRef?.[0]?.special_offer
              ? "Special Offers"
              : ""
          );

          let allProducts = [];

          dataPromoRef?.forEach(
            (doc) => (allProducts = allProducts?.concat(doc.products))
          );

          const data = await Promise.all(
            allProducts?.map(async (product) => {
              const variants = await firestore
                .collection(`products/${product.id}/variants`)
                .get();
              return {
                id: product.id,
                ...product,
                variants: variants.docs.map((variant) => {
                  return {
                    id: variant.id,
                    ...variant.data(),
                  };
                }),
              };
            })
          );
          let filter = data.filter((item) =>
            userToko.products.includes(item.id)
          );
          const dataKeywords = { "Semua Produk": "all" };

          filter.forEach((el) => {
            if (!dataKeywords.hasOwnProperty(el?.kategori1?.nama)) {
              dataKeywords[el?.kategori1?.nama] = el?.kategori1?.id;
            }
          });

          setKeywords(Object.entries(dataKeywords));

          // setSlicedProducts(filter?.slice(0, 5) ?? []);
          setProducts(filter ?? []);
          setLoading(false);
        } else {
          // setSlicedProducts([]);
          setProducts([]);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    getProducts();
  }, [promo]);

  const toDetailProduct = (id, title) => () => {
    // console.log("to detail...");
    analytics.logEvent("view_product", { name: title, id: id });

    history.push(`/produk/${slugify(title.toLowerCase())}/${id}`);
  };

  return !loading && products?.length && seconds > 0 ? (
    <Paper elevation={0} className={classes.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "bold",
            textTransform: "uppercase",
            color: "white",
          }}
        >
          {mode}
        </Typography>
        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          <Button
            size="small"
            endIcon={<ArrowRightIcon style={{ color: "white" }} />}
            onClick={() => setOpen(true)}
          >
            <Typography style={{ fontSize: "11px", color: "white" }}>
              Lihat semua
            </Typography>
          </Button>
          <DialogFlashsale
            open={open}
            handleClose={() => setOpen(false)}
            data={products}
            keywords={keywords}
            timer={expiration_date}
            mode={mode}
          />
        </div>
      </div>
      <ScrollContainer className={classes.wrapScroll}>
        <div className={classes.wrapper}>
          <div
            style={
              miniScreen
                ? {
                    backgroundColor: "#5675D3",
                    textAlign: "center",
                    marginTop: "40%",
                    padding: 10,
                  }
                : {
                    backgroundColor: "#5675D3",
                    textAlign: "center",
                    marginTop: "40%",
                    padding: 10,
                  }
            }
          >
            {mode === "Flashsale" ? (
              <img
                src={FlashSaleIcon}
                alt="icon"
                style={miniScreen ? { width: "100px" } : { width: "140px" }}
              />
            ) : mode === "Special Offers" ? (
              <img
                src={SpesialOfferIcon}
                alt="icon"
                style={miniScreen ? { width: "100px" } : { width: "140px" }}
              />
            ) : (
              ""
            )}
            <Typography
              style={
                miniScreen
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      color: "#FFF",
                      fontSize: "65%",
                    }
                  : {
                      display: "flex",
                      justifyContent: "center",
                      color: "#FFF",
                      fontSize: "100%",
                    }
              }
              component="p"
            >
              Berakhir dalam
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#FFF",
              }}
            >
              <Countdown date={expiration_date} renderer={Renderer} />
            </div>
          </div>
        </div>
        {products
          .sort((a, b) => {
            const stok_a = a?.variants?.map(
              (variant) => variant?.stok_available
            );
            const total_stok_a = stok_a?.reduce((val, nilaiSekarang) => {
              return val + nilaiSekarang;
            }, 0);
            const stok_b = b?.variants?.map(
              (variant) => variant?.stok_available
            );
            const total_stok_b = stok_b?.reduce((val, nilaiSekarang) => {
              return val + nilaiSekarang;
            }, 0);
            return total_stok_b - total_stok_a;
          })
          .map((item, index) => {
            const stok = item.variants.map((variant) => variant.stok_available);

            const harga = item.variants.map(
              (variant) => variant[`harga_normal`]
            );

            const harga_normal = item.variants.map(
              (variant) => variant?.harga_normal
            );

            const promoFound = findOnePromo(item, promo);

            const total_stok = stok.reduce((val, nilaiSekarang) => {
              return val + nilaiSekarang;
            }, 0);

            const emptyStock = total_stok === 0 ? true : false;

            const harga_str = arrayToCurrencyString(harga);

            const harga_str_promo =
              promoFound &&
              arrayToCurrencyStringPromo(
                harga_normal,
                promoFound,
                level?.level,
                global_komisi
              );

            const textBadgePromo = (() => {
              let text = "";

              if (promoFound) {
                if (promoFound?.tipe === "%") {
                  text = `${promoFound?.nilai}%`;
                } else {
                  text = `${promoFound?.nilai / 1000}K`;
                }
              }

              return text;
            })();
            if (index < 5) {
              return (
                <div className={classes.wrapper} key={index}>
                  <Card className={classes.card}>
                    <CardActionArea
                      onClick={toDetailProduct(item.id, item.nama)}
                    >
                      {promo && !emptyStock ? (
                        <div className={classes.label}>
                          <Typography className={classes.txtLabel}>
                            {textBadgePromo}
                          </Typography>
                          <Typography className={classes.txtLabel}>
                            OFF
                          </Typography>
                        </div>
                      ) : null}
                      {emptyStock ? (
                        <div className={classes.empty}>
                          <span className={classes.txtEmpty}>Stok Habis</span>
                        </div>
                      ) : null}
                      {item.thumbnail?.length > 0 ? (
                        <div
                          loading="lazy"
                          // src={item.thumbnail[0]}
                          alt={item.id}
                          className={classes.img}
                          style={{
                            backgroundImage: `url(${item?.thumbnail?.[0]})`,
                          }}
                        />
                      ) : (
                        <div
                          loading="lazy"
                          // src={item.thumbnail}
                          alt={item.id}
                          className={classes.img}
                          style={{
                            backgroundImage: `url(${item?.thumbnail})`,
                          }}
                        />
                      )}
                    </CardActionArea>
                    {/* <div className={classes.contentWrapper}> */}
                    <div className={classes.content}>
                      <div className={classes.labelFlash}>{mode}</div>
                      <Typography className={classes.title} component="p">
                        {item?.nama}
                      </Typography>
                      <Typography className={classes.price} component="p">
                        {promoFound ? harga_str_promo : harga_str}
                      </Typography>
                    </div>
                    <div className={classes.btnWrap}>
                      {emptyStock ? (
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={classes.btn}
                          style={{
                            backgroundColor: "rgb(255, 107, 107)",
                          }}
                        >
                          Stok Habis
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.btn}
                          onClick={toDetailProduct(item.id, item.nama)}
                        >
                          Beli
                        </Button>
                      )}
                    </div>
                    {/* </div> */}
                  </Card>
                </div>
              );
            }
            return null;
          })}
        {products?.length > 3 ? (
          <div
            style={{
              alignSelf: "center",
              paddingLeft: 37.5,
              paddingRight: 37.5,
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            <div style={{ width: 80 }}>
              <PlayCircleOutlineIcon
                style={{ width: "100%", color: "white", fontSize: 27.5 }}
              />
              <Typography
                style={{ fontSize: 12.5, color: "white", marginTop: 10 }}
                align="center"
              >
                Lihat Semua
              </Typography>
            </div>
          </div>
        ) : null}{" "}
      </ScrollContainer>
    </Paper>
  ) : null;
}

export default FlashSale;
