import algoliasearch from "algoliasearch/lite";

let appId = "VQZ9PYV18W";
let apiKey = "e163c166ace41092abad023935179263";
export const indexName = "products";

if (process.env.REACT_APP_FIREBASE_PROD === "YES") {
  appId = "I77G90VXYU";
  apiKey = "8d88942c154cb9c7d0b2810bbaaf520e";
}

export const searchClient = algoliasearch(appId, apiKey);
