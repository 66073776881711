import React from 'react';
import Katalog from "./Katalog"

const Akun = React.lazy(() => import('./Akun'))
const DaftarAlamat = React.lazy(() => import('./DaftarAlamat'))
const DetailProduk = React.lazy(() => import('./DetailProduk'))
// const Katalog = React.lazy(() => import('./Katalog'))
const Landing = React.lazy(() => import('./Landing'))
const ShoppingCart = React.lazy(() => import('./ShoppingCart'))
const Transaksi = React.lazy(() => import('./Transaksi'))
const NotFound = React.lazy(() => import('./404'))
const LayoutA = React.lazy(() => import('./LayoutA'))
const LayoutB = React.lazy(() => import('./LayoutB'))
const LayoutC = React.lazy(() => import('./LayoutC'))
const Finish = React.lazy(() => import('./Payment/Finish'))
const Kategori = React.lazy(() => import('./Kategori'))
const BrandGallery = React.lazy(() => import('./BrandGallery'))
const BrandDetail = React.lazy(() => import('./BrandDetail'))
const Form = React.lazy(() => import('./Form'))
const LayoutLanding = React.lazy(() => import('./LayoutLanding'))
const Tukuyok = React.lazy(() => import('./Tukuyok'))
const LayoutKatalog = React.lazy(() => import('./LayoutKatalog'))


export {
  Akun,
  DaftarAlamat,
  DetailProduk,
  Katalog,
  Landing,
  ShoppingCart,
  Transaksi,
  NotFound,
  LayoutA,
  LayoutB,
  LayoutC,
  LayoutLanding,
  Finish,
  Kategori,
  BrandGallery,
  BrandDetail,
  Form,
  Tukuyok,
  LayoutKatalog
};
