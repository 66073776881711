import React, { useEffect, useState } from "react";

// Context & Component's
import {
  firestore,
  SkeletonLoading,
  SkeletonProduct,
  Headerkatalog,
  useProduct,
} from "../../components";
//firebase
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
//router
import { useHistory } from "react-router-dom";
// Styles
import useStyles from "./styles";
import InfiniteScroll from "react-infinite-scroll-component";
//slugtify
import slugify from "slugify";

// React-Helmet
import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  CardActionArea,
  Container,
  Typography,
} from "@material-ui/core";
import { arrayToCurrencyString } from "../../utils";
import HelpBarKatalog from "../../components/atoms/HelpBarKatalog";

export default function Tukuyok() {
  const classes = useStyles();
  const {
    products,
    loadingMore,
    loadMoreProducts,
    loading,
    loadingTypesenseConfig,
    loadingConfiguration,
  } = useProduct();
  const history = useHistory();

  const [dialogSearch, setDialogSearch] = useState({ open: false });

  const onFocus = () => {
    history.push("/browse");
  };


  if (loading) {
    return <SkeletonLoading />;
  }

  if (loadingConfiguration && loadingTypesenseConfig) {
    return <SkeletonLoading />;
  }
  const toDetailProduct = (id, title) => {
    history.push(`/produk/${slugify(title.toLowerCase())}/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>Katalog Tokorame</title>
        <meta name="description" content="Katalog Tokorame" />
        <meta property="og:title" content="Katalog Tokorame" />
        <meta property="og:description" content="Katalog Tokorame" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/sommerce-develop.appspot.com/o/tokorame%2FBanner%20Tokorame%20(1).jpg?alt=media&token=9a303e3d-3664-4afa-a6cf-b9d178e282bf"
        />
        <meta property="og:site_name" content="Katalog Tokorame" />
        <meta property="og:url" content={`${window.location.origin}`} />
      </Helmet>
        <Headerkatalog onFocus={onFocus} />
        <img
          loading="lazy"
          className={classes.hero}
          src="https://firebasestorage.googleapis.com/v0/b/sommerce-develop.appspot.com/o/tokorame%2FBanner%20Tokorame%20(1).jpg?alt=media&token=9a303e3d-3664-4afa-a6cf-b9d178e282bf"
          alt="img"
        />
          <InfiniteScroll
            dataLength={products.length}
            style={{ overflow: "hidden" }}
            next={loadMoreProducts}
            hasMore={loadingMore}
            scrollThreshold
            loader={
              <div>
                {products.length === 0 ? null : products?.length > 1 ? (
                  <Typography
                    color="primary"
                    style={{ textAlign: "center", marginBottom: "20px" }}
                  >
                    <b>Memuat</b>
                  </Typography>
                ) : (
                  <SkeletonProduct />
                )}
              </div>

              // <SkeletonProduct />
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! Yay! Kamu Sudah Melihat Semua</b>
              </p>
            }
          >
            <div className={classes.productContent}>
              {products.map((data, index) => {
                const harga = data.variants.map(
                  (variant) => variant.harga_normal
                );

                const harga_str = arrayToCurrencyString(harga);

                return (
                  <div className={classes.wrapper} key={index}>
                    <Card className={classes.card}>
                      <CardActionArea
                        onClick={() => toDetailProduct(data.id, data.nama)}
                      >
                        {data.thumbnail?.length > 0 ? (
                          <div
                            loading="lazy"
                            // src={data.thumbnail[0]}
                            alt={data.id}
                            className={classes.img}
                            style={{
                              backgroundImage: `url(${data.thumbnail[0]})`,
                            }}
                          />
                        ) : (
                          <div
                            loading="lazy"
                            // src={data.thumbnail}
                            alt={data.id}
                            className={classes.img}
                            style={{
                              backgroundImage: `url(${data.thumbnail})`,
                            }}
                          />
                        )}
                      </CardActionArea>
                      <div className={classes.content}>
                        <Typography className={classes.title} component="p">
                          {data.nama}
                        </Typography>
                        <Typography className={classes.price} component="p">
                          {harga_str}
                        </Typography>
                      </div>
                      <div className={classes.btnWrap}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.btn}
                          onClick={() => toDetailProduct(data.id, data.nama)}
                        >
                          Lihat Produk
                        </Button>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
      <HelpBarKatalog />
    </>
  );
}
