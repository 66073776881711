import React from 'react'

// Icon
import { ArrowBack, Search, ShoppingCart } from '@material-ui/icons'

// Material-UI
import { InputBase, IconButton, Badge } from '@material-ui/core'

// Components
import { Gap } from '../../atoms'

// Styles
import useStyles from './styles/WithSearchBack'

// Router
import { useHistory } from 'react-router-dom'

function WithSearchBack({ onClick, value, placeholder, onChange, cartBadge }) {

    const classes = useStyles()

    const history = useHistory()

    return (
        <div
            className={classes.root}
        >
            <IconButton
                size="small"
                onClick={() => history.goBack()}
            >
                <ArrowBack className={classes.icon} />
            </IconButton>
            <Gap width={16} />
            <div className={classes.searchBox}>
                <Search className={classes.icSearch} />
                <Gap width={10} />
                <InputBase
                    placeholder={placeholder}
                    value={value}
                    autoFocus
                    inputProps={{ 'aria-label': placeholder }}
                    onChange={onChange}
                    fullWidth
                />
            </div>
            <Gap width={16} />
            <IconButton
                onClick={onClick}
                size="small"
            >
                <Badge badgeContent={cartBadge.length} color="error">
                    <ShoppingCart className={classes.icon} />
                </Badge>
            </IconButton>
        </div>
    )
}

export default WithSearchBack
