let firebaseConfig = {
  apiKey: "AIzaSyAaecI-W75B7ot-z2T1lMPZi4RrNt_lH3E",
  authDomain: "sommerce-develop.firebaseapp.com",
  databaseURL: "https://sommerce-develop.firebaseio.com",
  projectId: "sommerce-develop",
  storageBucket: "sommerce-develop.appspot.com",
  messagingSenderId: "512854204757",
  appId: "1:512854204757:web:6fbfc6c2e3c6c6333b4fdc",
  measurementId: "G-Z7RRHJRP2V",
};

if (process.env.REACT_APP_FIREBASE_PROD === "YES") {
  firebaseConfig = {
    apiKey: "AIzaSyBcbvFhJk4euXBPO2z4FCo4HkUYXioyTjQ",
    authDomain: "tokorame-prod.firebaseapp.com",
    projectId: "tokorame-prod",
    storageBucket: "tokorame-prod.appspot.com",
    messagingSenderId: "323491252299",
    appId: "1:323491252299:web:9575d602eea393537165ce",
    measurementId: "G-V18RS5QZXP",
  };
}

export default firebaseConfig;
