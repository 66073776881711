import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: 60,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
        width: 960,
        position: 'fixed',
        top: 0,
        margin: '0 auto',
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 999,
        transition: '.2s linear'
    },
    searchBox: {
        display: 'flex',
        backgroundColor: '#f0f0f0',
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: 'center',
        height: 40,
        borderRadius: 5,
        flex: 1
    },
    icSearch: {
        color: '#989898'
    },
    icCart: {
        color: '#ffff'
    },
    icCartBlack: {
        color: '#989898',
        transition: '.2s ease-in'
    }
}))

export default useStyles