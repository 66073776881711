import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    mainContainer: {
        flexGrow: 1,
        backgroundColor: '#FEFEFE',
        minHeight: '100vh',
        boxShadow: '0 0 3px 1px rgba(0,0,0,.1)',
    },
}))

export default useStyles