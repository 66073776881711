import React from "react";
import { useHistory } from "react-router-dom";
import slugify from "slugify";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./styles/newProdukStyle";
import { analytics, useData } from "../../components";
import {
  arrayToCurrencyString,
  arrayToCurrencyStringPromo,
  findOnePromo,
} from "../../utils";
import { DialogTitle, IconButton } from "@material-ui/core";

function DialogNewProduk({ open, handleClose, data, mode }) {
  const classes = useStyles();
  const history = useHistory();
  const { level, promo, global_komisi } = useData();

  const toDetailProduct = (id, title) => () => {
    // console.log("to detail...");
    analytics.logEvent("view_product", { name: title, id: id });

    history.push(`/produk/${slugify(title.toLowerCase())}/${id}`);
  };

  return (
    <Dialog
      // fullWidth
      // maxWidth="md"
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        style: { maxWidth: 960 },
      }}
      // TransitionComponent={Transition}
      // classes={{
      //   paperFullWidth: classesDialog.fullWidth,
      //   paperScrollPaper: classesDialog.paper,
      //   paper: classesDialog.root,
      //   root: classesDialog.root,
      // }}
    >
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            style={{ marginRight: 10 }}
            size="small"
            onClick={handleClose}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography style={{ fontSize: 17 }}>{mode}</Typography>
        </div>
      </DialogTitle>{" "}
      {/* <Header title="Flashsale" type="with-title" onClick={handleClose} /> */}
      <DialogContent dividers style={{ padding: "0px" }}>
        <Grid container className={classes.root}>
          {data
            .sort((a, b) => {
              const stok_a = a?.variants?.map(
                (variant) => variant.stok_available
              );
              const total_stok_a = stok_a?.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);
              const stok_b = b?.variants?.map(
                (variant) => variant?.stok_available
              );
              const total_stok_b = stok_b?.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);
              return total_stok_b - total_stok_a;
            })
            .map((item, index) => {
              const stok = item.variants.map(
                (variant) => variant.stok_available
              );

              const harga = item.variants.map(
                (variant) => variant[`harga_normal`]
              );

              const harga_normal = item.variants.map(
                (variant) => variant?.harga_normal
              );

              const promoFound = findOnePromo(item, promo);

              const total_stok = stok.reduce((val, nilaiSekarang) => {
                return val + nilaiSekarang;
              }, 0);

              const emptyStock = total_stok === 0 ? true : false;

              const harga_str = arrayToCurrencyString(harga);

              const harga_str_promo =
                promoFound &&
                arrayToCurrencyStringPromo(
                  harga_normal,
                  promoFound,
                  level?.level,
                  global_komisi
                );

              const textBadgePromo = (() => {
                let text = "";

                if (promoFound) {
                  if (promoFound?.tipe === "%") {
                    text = `${promoFound?.nilai}%`;
                  } else {
                    text = `${promoFound?.nilai / 1000}K`;
                  }
                }

                return text;
              })();

              return (
                <div className={classes.wrapper} key={index}>
                  <Card className={classes.card}>
                    <CardActionArea
                      onClick={toDetailProduct(item.id, item.nama)}
                    >
                      {promoFound && !emptyStock ? (
                        <div className={classes.label}>
                          <Typography className={classes.txtLabel}>
                            {textBadgePromo}
                          </Typography>
                          <Typography className={classes.txtLabel}>
                            OFF
                          </Typography>
                        </div>
                      ) : null}
                      {emptyStock ? (
                        <div className={classes.empty}>
                          <span className={classes.txtEmpty}>Stok Habis</span>
                        </div>
                      ) : null}
                      {item.thumbnail?.length > 0 ? (
                        <div
                          loading="lazy"
                          // src={item.thumbnail[0]}
                          alt={item.id}
                          className={classes.img}
                          style={{
                            backgroundImage: `url(${item.thumbnail[0]})`,
                          }}
                        />
                      ) : (
                        <div
                          loading="lazy"
                          // src={item.thumbnail}
                          alt={item.id}
                          className={classes.img}
                          style={{
                            backgroundImage: `url(${item.thumbnail})`,
                          }}
                        />
                      )}
                    </CardActionArea>
                    {/* <div className={classes.contentWrapper}> */}
                    <div className={classes.content}>
                      <div
                        className={
                          mode == "Pre Order"
                            ? classes.labelPre
                            : classes.labelFlash
                        }
                      >
                        {mode}
                      </div>
                      <Typography className={classes.title} component="p">
                        {item?.nama}
                      </Typography>
                      <div>
                        {item?.properties?.type === "preorder" ? (
                          ""
                        ) : (
                          <>
                            <Typography
                              className={classes.txtStockTersedia}
                              component="p"
                            >
                              Stok tersedia
                            </Typography>
                            <Typography
                              className={classes.txtStock}
                              component="p"
                            >
                              {total_stok} Pcs
                            </Typography>
                          </>
                        )}
                      </div>
                      <Typography className={classes.price} component="p">
                        {promoFound ? harga_str_promo : harga_str}
                      </Typography>
                    </div>
                    <div className={classes.btnWrap}>
                      {emptyStock ? (
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={classes.btn}
                          style={{
                            backgroundColor: "rgb(255, 107, 107)",
                          }}
                        >
                          Stok Habis
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.btn}
                          onClick={toDetailProduct(item.id, item.nama)}
                        >
                          Beli
                        </Button>
                      )}
                    </div>
                    {/* </div> */}
                  </Card>
                </div>
              );
            })}
        </Grid>
        {/* </TabPanel>
        </div> */}
      </DialogContent>
    </Dialog>
  );
}

export default DialogNewProduk;
