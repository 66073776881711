import React from "react";

// React-Router
import { Switch, Route } from "react-router-dom";

// Material-UI
import { Container } from "@material-ui/core";

// Styles
import useStyles from "./styles";

// Components
import { Tukuyok } from "..";
import DetailProduk from "../Tukuyok/detailProduk";
import Browse from "../Tukuyok/browse";

function LayoutKatalog() {
  const classes = useStyles();

  return (
    <Container
      maxWidth="md"
      classes={{ root: classes.container }}
      className={classes.mainContainer}
    >
      <Switch>
        <Route exact path="/" component={Tukuyok} />
        <Route path="/produk/:name/:id" component={DetailProduk} />
        <Route path="/browse" component={Browse} />
      </Switch>
    </Container>
  );
}

export default LayoutKatalog;
