export const duration = (min, max) => {
  if (max === null && min === null) {
    return "";
  } else if (max === null) {
    return `(${min} hari)`;
  } else if (min === null) {
    return `(${max} hari)`;
  } else if (min === max) {
    return `(${min} hari)`;
  }

  return `(${min}-${max} hari)`;
};
