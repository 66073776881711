import React from "react";

function Divider({ height = 8 }) {
  return (
    <div
      style={{
        margin: "0 auto",
        height: height,
        width: "100%",
        backgroundColor: "#f5f5f5",
        borderTop: "1px solid #e5e5e5",
        borderBottom: "1px solid #e5e5e5",
      }}
    />
  );
}

export default Divider;
