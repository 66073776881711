import React from "react";

// Icon
import { ArrowBack } from "@material-ui/icons";

// Styles
import useStyles from "./styles/Wt";

// Material-UI
import { Typography, IconButton } from "@material-ui/core";

// Components
import { Gap } from "../../atoms";

function WithTitle({ title, onClick, back }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {back ? (
        <IconButton
          size="small"
          style={{ backgroundColor: "transparent" }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={onClick}
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
      ) : null}
      <Typography className={classes.title}>{title}</Typography>
      {back ? <Gap width={30} /> : null}
    </div>
  );
}

export default WithTitle;
