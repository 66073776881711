import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    display: "flex",
    flexWrap: "wrap",
    // marginTop: "10px",
    padding: "10px",
  },
  wrapper: {
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 15,
    "@media(min-width: 280px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 320px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 360px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 400px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 600px)": {
      width: "calc(100%/3)",
      height: "100%",
    },
    "@media(min-width: 960px)": {
      width: "calc(100%/4)",
      height: "100%",
    },
  },
  card: {
    height: "100%",
    borderRadius: 5,
    boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
  },

  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  mainContainer: {
    flexGrow: 1,
    backgroundColor: "#FEFEFE",
    minHeight: "100vh",
    boxShadow: "0 0 3px 1px rgba(0,0,0,.1)",
  },
  intoSke: {
    padding: 10,
  },
}));

export default useStyles;
