import format from "date-fns/format";
import { id } from "date-fns/locale";

export const date = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "dd-LL-yyy 'pukul' HH:mm:ss", {
    locale: id,
  });
};

export const fullDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "dd MMMM Y 'pukul' HH.mm", {
    locale: id,
  });
};

export const trackDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "cccc, dd MMM yyy", {
    locale: id,
  });
};

export const convertDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "dd MMMM Y", {
    locale: id,
  });
};

export const convertTime = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "hh:mm 'WIB'");
};
