import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "50px",
    marginBottom: "100px",
  },
  container: {
    padding: "10px",
    margin:"5px 0px",
    borderRadius: "0px",
    background: "linear-gradient(180deg, #5675D3 60%, #E5E5E5 40%)",
  },
  wrapScroll: {
    display: "flex",
    overflowX: "auto",
    flexWrap: "nowrap",
  },
  wrapper: {
    flex: "0 0 auto",
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 15,
    "@media(min-width: 280px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 320px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 360px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 400px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 600px)": {
      width: "calc(100%/3)",
      height: "100%",
    },
    "@media(min-width: 960px)": {
      width: "calc(100%/4)",
      height: "100%",
    },
  },
  card: {
    flex: "0 0 auto",
    height: "100%",
    borderRadius: 5,
    boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
  },
  label: {
    position: "absolute",
    boxShadow: "2px 2px darkslategrey",
    right: "5px",
    backgroundColor: "#e82868",
    lineHeight: "20px",
    color: "#fff",
    display: "inline-block",
    padding: "5px 5px",
    borderRadius: "0px 0px 20px 20px",
    marginBottom: 4,
    textAlign: "center",
  },
  img: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 270,
    },
  },
  img_seceleton: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 300,
    },
  },
  contentWrapper: {
    height: 122,
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 10px",
    marginTop: 8,
    justifyContent: "space-between",
    height: 80,
  },
  title: {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "center",
    color: "#333333",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "@media(min-width: 280px)": {
      fontSize: 12,
    },
    "@media(min-width: 320px)": {
      fontSize: 12,
    },
    "@media(min-width: 360px)": {
      fontSize: 14,
    },
    "@media(min-width: 960px)": {
      fontSize: 14,
    },
  },
  price: {
    fontWeight: 700,
    fontSize: 12,
    color: "#eb6669",
    textAlign: "center",
    maxWidth: 139,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(max-width: 280px)": {
      width: 100,
    },
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
    "@media(min-width: 960px)": {
      maxWidth: 150,
    },
  },
  btnWrap: {
    width: "90%",
    position: "absolute",
    bottom: "10px",
    left: "0px",
    right: "0px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  btn: {
    color: "#ffff",
    fontWeight: 700,
    fontSize: 12,
    "@media(max-width: 280px)": {
      padding: "4px 4px",
    },
    textTransform: "capitalize",
  },
  empty: {
    backgroundColor: "hsla(0,0%,100%,.9)",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  txtEmpty: {
    fontSize: 18,
    fontWeight: 700,
    color: "rgba(0,0,0,.4)",
  },
  txtLabel: {
    fontSize: 12,
    color: "#FFF",
  },
  txtLabelharhag: {
    fontSize: 12,
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
  },
  labelKomisi: {
    backgroundColor: "#e82868",
    fontSize: 11,
    lineHeight: "20px",
    color: "#fff",
    display: "inline-block",
    padding: "0 4px",
    borderRadius: 4,
    marginBottom: 4,
    "@media(min-width: 280px)": {
      fontSize: 9,
      padding: "0 3px",
    },
    "@media(min-width: 320px)": {
      fontSize: 9,
      padding: "0 3px",
    },
    "@media(min-width: 360px)": {
      fontSize: 9,
      padding: "0 3px",
    },
    "@media(min-width: 960px)": {
      fontSize: 11,
      padding: "0 4px",
    },
  },
  large: {
    width: 100,
    height: 100,
  },
  liquidBold: {
    fontWeight: 700,
    fontSize: 12,
    color: "#00000",
    textAlign: "center",
    maxWidth: 139,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(max-width: 280px)": {
      width: 100,
    },
    "@media (max-width: 360px)": {
      fontSize: 10,
    },
    "@media(min-width: 960px)": {
      maxWidth: 150,
    },
  },
  tab: {
    fontSize: "13px",
    "@media (max-width: 480px)": {
      fontSize: "11px",
    },
  },
  liquidTitle: {
    fontSize: "15px",
    color: "FFFFFF",
  },
  dialog: {
    padding: "0px !important",
  },
  dialogContent: {
    paddingTop: "0px !important",
    padding: "0px !important",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  hero: {
    objectFit: "cover",
    width: "100%",
  },
  wrapHero: {
    minHeight: "207px",
    minWidth: "100%",
  },
  wrapButtonClose: {
    position: "absolute",
    right: 0,
    padding: 5,
  },
  wrapContent: {
    padding: "0px 10px 10px 10px",
  },
  textDesc: {
    display: "inline",
    width: "100%",
    fontSize: "12px",
  },
  showMore: {
    color: "rgb(192,192,192)",
    cursor: "pointer",
  },
  wrapIcon: {
    width: 40,
    "@media(max-width: 280px)": {
      width: 35,
    },
    "@media (max-width: 360px)": {
      width: 35,
    },
    "@media(min-width: 960px)": {
      width: 35,
    },
  },
  icon:{
      objectFit: "cover",
      width: "100%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
  }
}));
export default useStyles;
