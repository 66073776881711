import React from "react";

// useStyles
import useStyles from "./styles/WithCart";

// Material-UI
import { IconButton, Typography } from "@material-ui/core";

// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function WithTitleNoCart({ onClick, title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton size="small" onClick={onClick}>
        <ArrowBackIcon className={classes.icon} />
      </IconButton>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
}

export default WithTitleNoCart;
