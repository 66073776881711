import React from "react"
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#F0F0F0",
    borderRadius: 50,
    fontSize: "0.875rem",
    padding: 15,
    backgroundColor: "#E61759",
    "&:hover": {
      backgroundColor: "#a1103e",
    },
    "@media(min-width: 280px)": {
      fontSize: "0.75rem",
    },
    "@media(min-width: 320px)": {
      fontSize: "0.75rem",
    },
    "@media(min-width: 360px)": {
      fontSize: "0.75rem",
    },

    "@media(min-width: 960px)": {
      fontSize: "0.875rem",
    },
  },
}))(Button);

function ButtonDaftar({ onClick, titleButton }) {
  return <ColorButton onClick={onClick}>{titleButton}</ColorButton>;
}

export default ButtonDaftar;
