import React, { useState } from "react";

import { useDocumentData } from "react-firebase-hooks/firestore";

// React Router
import { Route, Redirect } from "react-router-dom";

// Context
import {
  useFirebase,
  firestore,
} from "../../components/context/FirebaseProvider";

// Component
import Tukuyok from "../../pages/Tukuyok";
import { Progress } from "../../components";

export default function LandingRoute({ component: Component, ...restProps }) {
  const [subdomain] = useState(window.location.hostname.split("."));

  const { subdomainDetail } = useFirebase();

  const levelRef = firestore.doc(`levels/${subdomainDetail?.user_id}`);

  const [levels, loadingLevels] = useDocumentData(levelRef);

  if (loadingLevels) {
    return <Progress />;
  }

  return (
    <Route
      {...restProps}
      render={(props) => {
        return subdomain.length <= 2 || subdomain[0] === "www" ? (
          <Tukuyok />
        ) : subdomain.length >= 3 &&
          subdomainDetail !== undefined &&
          levels?.level === "agen" || levels?.level === "distributor" && levels?.landing === true || levels?.level === "mentorbisnis" && levels?.landing === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/404-rna",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
}
