import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    width: 960,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    margin: "0 auto",
    paddingLeft: 16,
    paddingRight: 16,
    zIndex: 999,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  icon: {
    color: "black",
  },
  title: {
    textAlign: "center",
    flex: 1,
    fontSize: 18,
    color: "black",
    fontWeight: 400,
  },
}));

export default useStyles;
