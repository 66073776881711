import React from "react";

// Material UI
import { BottomNavigationAction, BottomNavigation } from "@material-ui/core";
import { AccountCircle, WhatsApp, Store, Category } from "@material-ui/icons";

// Router
import { Link, withRouter } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Context
import { useData } from "../../context/DataProvider";

function BottomNav({ location }) {
  const classes = useStyles();

  const { userToko } = useData();

  const wa = userToko && userToko?.wa.replace(/-|[+]/gm, "");

  return (
    <BottomNavigation
      value={location.pathname}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        className={classes.action}
        label="Toko"
        value="/"
        component={Link}
        to="/"
        icon={<Store />}
      />
      <BottomNavigationAction
        className={classes.action}
        label="Kategori"
        value="/kategori"
        component={Link}
        to="/kategori"
        icon={<Category />}
      />
      <BottomNavigationAction
        className={classes.action}
        href={`https://wa.me/${wa}`}
        target="_blank"
        label="Kontak"
        icon={<WhatsApp />}
      />
      <BottomNavigationAction
        className={classes.action}
        label="Akun"
        value="/akun"
        component={Link}
        to="/akun"
        icon={<AccountCircle />}
      />
    </BottomNavigation>
  );
}

export default withRouter(BottomNav);
