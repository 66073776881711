import * as React from "react"

const MoneyWings = (props) => (
  <svg
    width={33}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.453 10.777c-3.197-2.888-6.394-5.827-9.59-8.714C17.17 9.178 9.23 15.52 1.701 20.108c-.207.98-.413 1.908-.67 2.836 3.3 2.99 6.6 6.033 9.9 9.024 7.785-4.28 16.035-10.364 21.037-17.274-.155-1.289-.361-2.578-.516-3.918Z"
      fill="#5675D3"
    />
    <path
      d="M11.55 28.153c-3.248-2.99-6.497-5.981-9.745-8.92C9.9 14.643 16.706 8.405 21.708 1.03c3.248 2.99 6.497 5.93 9.745 8.92-5.001 7.374-11.808 13.613-19.903 18.202Z"
      fill="#6F9CD2"
    />
    <path
      d="M10.725 26.194c-2.166-1.96-4.331-3.97-6.497-5.93.155-.103.258-.155.413-.258.412-.258.515-.722.154-1.083l-.31-.309c6.55-4.022 12.221-9.178 16.656-15.16l.309.31c.36.31.877.258 1.186-.103.103-.103.155-.258.258-.361 2.165 1.96 4.331 3.97 6.497 5.93-.103.103-.155.258-.258.36-.31.413-.258.98.103 1.341l.31.31C25.11 17.17 19.438 22.327 12.89 26.348l-.31-.309c-.36-.31-.98-.36-1.444-.103-.154.103-.257.155-.412.258ZM5.62 20.316c1.753 1.598 3.507 3.197 5.311 4.847.67-.207 1.444-.155 2.063.154 5.981-3.815 11.189-8.56 15.365-14.076a2.083 2.083 0 0 1-.154-1.908c-1.753-1.599-3.507-3.197-5.311-4.847-.464.412-1.135.464-1.702.155-4.176 5.568-9.384 10.312-15.365 14.128.309.515.257 1.134-.207 1.547Z"
      fill="#5675D3"
    />
    <path
      d="M10.313 19.233c.567-.206 1.185-.155 1.598.206-.258.206-.567.413-.825.619-.103-.103-.361-.103-.464 0-.155.103-.206.258-.052.36.103.104.361.104.516 0 .619-.412 1.495-.463 1.96 0 .36.362.412.877.05 1.29.104.103.207.155.31.258-.258.154-.515.36-.773.515-.103-.103-.206-.206-.31-.258-.567.155-1.186.104-1.546-.257.309-.207.567-.361.876-.568.103.104.361.104.516 0 .154-.103.154-.257.051-.36-.103-.104-.31-.104-.464 0-.36.257-.825.36-1.237.309a1.747 1.747 0 0 1-.774-.31c-.36-.36-.36-.825-.051-1.237-.103-.052-.155-.155-.258-.258.258-.155.516-.36.773-.515-.051.051.052.103.104.206ZM22.274 8.198c.464-.309 1.031-.309 1.392.052l-.619.773a.273.273 0 0 0-.412.052c-.103.155-.103.36 0 .464.103.103.31.103.412-.051.465-.568 1.238-.67 1.702-.258.36.36.464.928.258 1.443.103.104.206.155.31.258-.207.258-.362.464-.568.722-.103-.103-.206-.206-.31-.258-.463.258-.98.258-1.34-.103.206-.258.464-.515.67-.773.103.103.31.103.413-.052.103-.155.103-.36 0-.464a.273.273 0 0 0-.413.052c-.258.31-.67.515-1.03.515-.259 0-.517-.103-.723-.258-.36-.36-.464-.876-.258-1.392-.103-.051-.154-.154-.257-.258.206-.206.412-.464.567-.721 0 .103.103.154.206.257Zm-1.547 10.107c-1.65 1.495-4.434 1.443-6.033 0-1.598-1.496-1.495-3.816.155-5.311 1.65-1.496 4.228-1.65 5.827-.155a3.636 3.636 0 0 1 .051 5.466Z"
      fill="#5675D3"
    />
    <path
      d="m11.24 12.633 9.746 8.92c1.134-.928 2.166-1.908 3.197-2.939l-9.694-8.92a92.528 92.528 0 0 1-3.248 2.939Z"
      fill="#fff"
    />
    <path
      d="M21.038 25.472a77.65 77.65 0 0 0 3.196-2.578v-4.331a56.777 56.777 0 0 1-3.197 2.939v3.97Z"
      fill="#DBB471"
    />
    <path
      d="M6.24 12.736c.566-.206 1.03-.516 1.494-.877.464-.36.877-.722 1.341-1.134.464-.36.928-.773 1.444-1.031.515-.31 1.186-.464 1.753-.361-.567.103-1.083.36-1.547.722-.464.31-.877.722-1.34 1.082-.465.361-.929.774-1.444 1.083-.516.31-1.135.516-1.702.516Z"
      fill="#8D9998"
    />
    <path
      d="M20.522 17.067s2.526.722 8.405-.825c5.878-1.547 3.093 15.366-7.632 15.366-1.186 0-1.237-1.495 2.063-4.177 0 0-4.64 1.083-2.63-2.372 0 0-3.3.568-1.805-2.062 0 0 .67-1.96.052-4.177.052 0 .412-1.134 1.547-1.753Z"
      fill="#E8E8E8"
    />
    <path
      d="M31.71 17.892c.104 4.538-3.505 12.066-10.672 12.066h-.104c-.67 1.083-.36 1.701.361 1.701 8.147-.051 11.705-9.745 10.416-13.767Z"
      fill="#D1D1D1"
    />
    <path
      d="M27.998 20.367c-1.546 5.569-7.734 6.033-7.734 6.033-.258 1.186.258 1.702.928 1.702 2.269.051 6.497-3.713 6.806-7.735Zm-4.33 0c-3.662 5.156-4.95 3.249-4.95 3.249-.929 2.062 1.494 2.062 1.907 1.598 1.186-1.289 3.094-3.97 3.042-4.847Z"
      fill="#D1D1D1"
    />
    <path
      d="M20.883 30.113c.619-.825 1.392-1.496 2.165-2.166.774-.67 1.547-1.34 2.32-2.063.723-.721 1.445-1.547 1.96-2.475.516-.928.774-2.01.67-3.042a6.086 6.086 0 0 1-1.03 2.836 16.672 16.672 0 0 1-1.96 2.32c-.722.722-1.495 1.393-2.217 2.166a7.953 7.953 0 0 0-1.908 2.424Zm-.619-3.713c.206-.567.516-1.031.877-1.495.36-.464.722-.877 1.134-1.34.36-.465.773-.93 1.031-1.445.31-.515.464-1.186.361-1.753-.103.567-.36 1.083-.722 1.547-.31.464-.722.877-1.082 1.34-.361.465-.774.929-1.083 1.444-.31.516-.516 1.135-.516 1.702Z"
      fill="#8D9998"
    />
    <path
      d="M15.572 12.478s-.722-2.526.825-8.405C17.944-1.805 1.03.98 1.03 11.705c0 1.186 1.496 1.237 4.177-2.063 0 0-1.083 4.64 2.372 2.63 0 0-.567 3.3 2.062 1.805 0 0 1.96-.67 4.177-.052 0-.052 1.134-.412 1.753-1.547Z"
      fill="#E8E8E8"
    />
    <path
      d="M14.747 1.29C10.209 1.185 2.68 4.794 2.68 11.961v.104c-1.083.618-1.65.36-1.65-.361C1.031 3.558 10.725 0 14.747 1.289Z"
      fill="#D1D1D1"
    />
    <path
      d="M12.272 5.002c-5.569 1.546-6.033 7.734-6.033 7.734-1.186.258-1.702-.258-1.702-.928-.051-2.269 3.713-6.497 7.735-6.806Zm0 4.33c-5.156 3.662-3.249 4.95-3.249 4.95-2.062.929-2.062-1.495-1.598-1.907 1.29-1.186 3.97-3.094 4.847-3.042Z"
      fill="#D1D1D1"
    />
    <path
      d="M2.527 12.117c.825-.619 1.495-1.392 2.165-2.165.67-.774 1.34-1.547 2.063-2.32.722-.723 1.547-1.444 2.475-1.96.928-.516 2.01-.774 3.042-.67a6.086 6.086 0 0 0-2.836 1.03 16.678 16.678 0 0 0-2.32 1.96c-.722.722-1.393 1.496-2.166 2.217-.722.774-1.495 1.444-2.423 1.908Z"
      fill="#8D9998"
    />
  </svg>
)

export default MoneyWings
