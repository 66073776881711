import React, { useEffect, useState } from 'react'

// PropTypes
import PropTypes from 'prop-types'

// Component
import AppSelect from './index'
import { functions } from '../../context'

function KelurahanSelect({ TextFieldProps = {}, kecamatan_id, kota_id, provinsi_id, ...props }) {

    const [kelurahan, setKelurahan] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (kecamatan_id) {
            const getDesa = async () => {
                const getKelurahan = functions.httpsCallable('getKelurahan')

                try {
                    setLoading(true)

                    const res = await getKelurahan({
                        kecamatan_id: kecamatan_id,
                        kota_id :kota_id,
                        provinsi_id: provinsi_id,
                    })

                    setKelurahan(res.data)

                    setLoading(false)

                } catch (error) {
                    console.log(error.message)

                    setLoading(false)
                }
            }

            getDesa()
        }
    }, [kecamatan_id, kota_id, provinsi_id])

    return <AppSelect
        disabled={!kecamatan_id || TextFieldProps.disabled}
        options={kelurahan ? kelurahan.map(data => ({ label: data.name, value: data.id, alias: data.alias || "", postcode: data.postcode })) : []}
        inputId="react-select-kelurahan"
        placeholder="Pilih Kelurahan"
        TextFieldProps={{

            label: 'Kelurahan',
            InputLabelProps: {
                htmlFor: 'react-select-kelurahan',
                shrink: true,
            },
            ...TextFieldProps

        }}
        {...props}
        loading={loading}
        isDisabled={!kecamatan_id}
    />
}

KelurahanSelect.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    kecamatan_id: PropTypes.number,
    kota_id:PropTypes.number,
    provinsi_id:PropTypes.number,
}

export default KelurahanSelect
